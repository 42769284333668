import React from 'react';

import { Box, Text, Button, Switch, FormLabel, FormControl } from '@chakra-ui/react';
import itemTypes from '../../../../util/itemTypes';

import { DropTarget } from 'react-dnd';
import FormInputOption from './FormInputOption.react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { mdiPlus } from '@mdi/js';
import { Icon } from '../Icon';

import { settingsLabelStyle } from './Settings.styles';
import { Tooltip } from '../../content/helper/Tooltip.react';

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

class FormInputOptions extends React.PureComponent {
	render() {
		return (
			<Box
				mb={5}
				className="text-input-setting-container"
				borderTopWidth={1}
				borderTopStyle="solid"
				borderTopColor="chakra-border-color"
				borderBottomWidth={1}
				borderBottomStyle="solid"
				borderBottomColor="chakra-border-color"
				display={'flex'}
				gap={2}
				py={2}
				flexDirection={'column'}
			>
				{this.props.label && (
					<FormLabel {...settingsLabelStyle}>
						<Box display="inline-flex" pos="relative">
							{this.props.label}
							<Tooltip>Enabling custom values lets you specify what to send to the webhook instead of the option text.</Tooltip>
						</Box>
					</FormLabel>
				)}
				<FormControl display="flex" alignItems="center">
					<FormLabel htmlFor="toggle-custom-values" mb="0">
						Custom values
					</FormLabel>
					<Switch id="toggle-custom-values" isChecked={this.props.hasCustomValues} onChange={this.toggleCustomValues} />
				</FormControl>
				<Box>
					{this.props.options && this.props.options.length ? (
						<DragDropContext onDragEnd={this.onDragEnd}>
							<Droppable droppableId="droppable">
								{(provided, snapshot) => (
									<Box {...provided.droppableProps} ref={provided.innerRef} display="flex" gap={2} flexDirection="column">
										{this.props.options.map((item, i) => {
											return (
												<Draggable key={`item-${i}`} draggableId={`item-${i}`} index={i}>
													{(provided) => (
														<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
															<FormInputOption
																{...item}
																hasCustomValues={this.props.hasCustomValues}
																index={i}
																onRemoveOptions={this.onRemoveOptions}
																onChangeInput={this.onChangeInput}
															/>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</Box>
								)}
							</Droppable>
						</DragDropContext>
					) : (
						<Text p={2.5} display="flex" alignItems="center" justifyContent="center">
							No options available
						</Text>
					)}
				</Box>

				<Box display="flex" justifyContent="center" mt={2}>
					<Button variant="ghost" colorScheme="primary" leftIcon={<Icon path={mdiPlus} />} onClick={this.onAddOption}>
						Add option
					</Button>
				</Box>
			</Box>
		);
	}

	toggleCustomValues = (e) => {
		this.props.onChange && this.props.onChange({ type: 'hasCustomValues', value: e.target.checked });
		if (!e.target.checked) {
			const { options } = this.props;
			const optionsArray = [...options].map((item) => ({ label: item.label, value: item.label }));
			this.props.onChange && this.props.onChange({ type: 'options', value: optionsArray });
		}
	};

	onDragEnd = (result) => {
		const { options } = this.props;
		let optionsArray = [...options];

		if (!result.destination) {
			return;
		}

		optionsArray = reorder(optionsArray, result.source.index, result.destination.index);
		this.props.onChange && this.props.onChange({ type: 'options', value: optionsArray });
	};

	onRemoveOptions = (index) => {
		const { options } = this.props;
		const optionsArray = [...options];
		optionsArray.splice(index, 1);
		this.props.onChange && this.props.onChange({ type: 'options', value: optionsArray });
	};

	onChangeInput = (value, type, index) => {
		const { options } = this.props;
		const optionsArray = [...options];
		const newValue = this.props.hasCustomValues ? { ...options[index], [type]: value } : { label: value, value };
		optionsArray.splice(index, 1, newValue);
		this.props.onChange && this.props.onChange({ type: 'options', value: optionsArray });
	};

	onAddOption = () => {
		const option = { value: `Option ${this.props.options.length + 1}`, label: `Option ${this.props.options.length + 1}` };
		const newOptions = [...this.props.options, option];
		this.props.onChange && this.props.onChange({ type: 'options', value: newOptions });
	};
}

const optionTarget = {
	drop(props, monitor, component) {
		const sourceObj = monitor.getItem();
		return {
			option: sourceObj,
		};
	},
};

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		canDrop: monitor.canDrop(),
		draggedField: monitor.getItem(),
	};
}

export default DropTarget(itemTypes.FORM_FIELD_OPTION, optionTarget, collect)(FormInputOptions);
