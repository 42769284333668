import strings from '../resources/strings/en-us';
import config from '../../config/config';
import { variables } from '@moosend/mooskin';

//menu element icons
import imageIcon from 'assets/images/left_elements/element_image.svg';
import textIcon from 'assets/images/left_elements/element_text.svg';
import buttonIcon from 'assets/images/left_elements/element_button.svg';
import articleIcon from 'assets/images/left_elements/element_article.svg';
import spacerIcon from 'assets/images/left_elements/element_spacer.svg';
import socialFollowIcon from 'assets/images/left_elements/element_social_follow.svg';
import htmlIcon from 'assets/images/left_elements/element_html.svg';
import timerIcon from 'assets/images/left_elements/element_timer.svg';
import videoIcon from 'assets/images/left_elements/element_video.svg';
import formIcon from 'assets/images/left_elements/element_form.svg';
import productIcon from 'assets/images/left_elements/element_product.svg';
import couponIcon from 'assets/images/left_elements/element_coupon.png';
import checkboxIcon from 'assets/images/left_elements/Checkbox.svg';
import dateIcon from 'assets/images/left_elements/Date.svg';
import checkboxesIcon from 'assets/images/left_elements/Checkboxes.svg';
import emailIcon from 'assets/images/left_elements/Email.svg';
import gdprIcon from 'assets/images/left_elements/GDPR.svg';
import longTextIcon from 'assets/images/left_elements/Long_text.svg';
import multiSelectIcon from 'assets/images/left_elements/Multi_select.svg';
import numberIcon from 'assets/images/left_elements/Number.svg';
import phoneIcon from 'assets/images/left_elements/Phone.svg';
import radioIcon from 'assets/images/left_elements/Radio.svg';
import recaptchaIcon from 'assets/images/left_elements/Recap.svg';
import shortTextIcon from 'assets/images/left_elements/Short_text.svg';
import singleSelectIcon from 'assets/images/left_elements/Single_select.svg';
import submitButtonIcon from 'assets/images/left_elements/Submit_button.svg';
import fileUploadIcon from 'assets/images/left_elements/file_upload.svg';

//helper grid element icons
import imageIconGrid from 'assets/images/helper_elements/mainarea_element_image.png';
import textIconGrid from 'assets/images/helper_elements/mainarea_element_text.png';
import buttonIconGrid from 'assets/images/helper_elements/mainarea_element_button.png';
import articleIconGrid from 'assets/images/helper_elements/mainarea_element_article.png';
import spacerIconGrid from 'assets/images/helper_elements/mainarea_element_spacer.png';
import socialFollowIconGrid from 'assets/images/helper_elements/mainarea_element_social_follow.png';
import htmlIconGrid from 'assets/images/helper_elements/mainarea_element_html.png';
import timerIconGrid from 'assets/images/helper_elements/mainarea_element_timer.png';
import videoIconGrid from 'assets/images/helper_elements/mainarea_element_video.png';
import formIconGrid from 'assets/images/helper_elements/mainarea_element_form.png';
import couponIconGrid from 'assets/images/helper_elements/mainarea_element_coupon.png';
import productIconGrid from 'assets/images/helper_elements/mainarea_element_product.png';

//Public custom row images
import FullWidthRSSArticleIcon from 'assets/images/left_public_structures/full_rss.svg';
import FullWidthCartAbandonmentIcon from 'assets/images/left_public_structures/full_cart.svg';
import FullWidthProductRecommendationIcon from 'assets/images/left_public_structures/full_product.svg';
import HalfWidthProductRecommendationIcon from 'assets/images/left_public_structures/half_product.svg';
import ThirdRSSArticleIcon from 'assets/images/left_public_structures/13_rss.svg';
import ThirdCartAbandonmentIcon from 'assets/images/left_public_structures/13_cart.svg';

//helper public custom row images
import FullWidthRSSArticleIconGrid from 'assets/images/helper_public_structures/full_rss_grey.png';
import FullWidthCartAbandonmentIconGrid from 'assets/images/helper_public_structures/full_cart_grey.png';
import FullWidthProductRecommendationIconGrid from 'assets/images/helper_public_structures/full_product_grey.png';
import HalfWidthProductRecommendationIconGrid from 'assets/images/helper_public_structures/half_product_grey.png';
import ThirdRSSArticleIconGrid from 'assets/images/helper_public_structures/13_rss_grey.png';
import ThirdCartAbandonmentIconGrid from 'assets/images/helper_public_structures/13_cart_grey.png';

//form type icons
import inlineIcon from 'assets/images/form_types/inline.png';
import fullPageIcon from 'assets/images/form_types/fullpage.png';
import popupIcon from 'assets/images/form_types/popup.png';
import bottomStickyIcon from 'assets/images/form_types/bottomsticky.png';
import rowIcon from 'assets/images/form_types/row.png';

import { mdiAlertCircle } from '@mdi/js';

export const EditorTypes = {
	editor: 'editor',
	landingPage: 'landingPage',
	popup: 'popup',
	inline: 'inline',
	smartbar: 'smartbar',
	scrollbox: 'scrollbox',
	welcomemat: 'welcomemat',
	promotion: 'promotion',
};

export const FormTypes = ['none', 'popup', 'inline', 'smartbar', 'scrollbox', 'welcomemat'];

export const FormWidthLimit = {
	popup: 800,
	inline: 3000,
	smartbar: 1200,
	scrollbox: 600,
	welcomemat: 1200,
	promotion: 1000,
};

export const RowTypes = {
	THREE_ONE_THIRDS: 'THREE_ONE_THIRDS',
	FOUR_ONE_FOURTHS: 'FOUR_ONE_FOURTHS',
	TWO_THIRDS_LEFT: 'TWO_THIRDS_LEFT',
	TWO_THIRDS_RIGHT: 'TWO_THIRDS_RIGHT',
	FULL: 'FULL',
	HALF: 'HALF',
};

export const SlotTypes = {
	ONE_THIRD: 'ONE_THIRD',
	ONE_FOURTH: 'ONE_FOURTH',
	FULL: 'FULL',
	HALF: 'HALF',
	TWO_THIRDS: 'TWO_THIRDS',
};

export const FieldComponentTypes = {
	submit_button: 'submit_button',
	email_input: 'email_input',
	phone_input: 'phone_input',
	text_input: 'text_input',
	longtext_input: 'longtext_input',
	singleselect_input: 'singleselect_input',
	multiselect_input: 'multiselect_input',
	date_input: 'date_input',
	number_input: 'number_input',
	radio: 'radio',
	checkbox: 'checkbox',
	checkbox_group: 'checkbox_group',
	gdpr: 'gdpr',
	recaptcha: 'recaptcha',
	file_upload: 'file_upload',
};

export const FieldComponentGroupTypes = {
	fields: 'fields',
	checkboxes: 'checkboxes',
	radios: 'radios',
	buttons: 'buttons',
	images: 'images',
	spacers: 'spacers',
	texts: 'texts',
	socials: 'socials',
	other: 'other',
};

export const BasicComponentTypes = {
	image: 'image',
	text: 'text',
	// button: 'button',
	spacer: 'spacer',
	social_follow: 'social_follow',
};

export const FieldComponentGroups = {
	[FieldComponentGroupTypes.fields]: [
		FieldComponentTypes.email_input,
		FieldComponentTypes.phone_input,
		FieldComponentTypes.text_input,
		FieldComponentTypes.longtext_input,
		FieldComponentTypes.singleselect_input,
		FieldComponentTypes.multiselect_input,
		FieldComponentTypes.date_input,
		FieldComponentTypes.number_input,
		FieldComponentTypes.file_upload,
	],
	[FieldComponentGroupTypes.other]: [FieldComponentTypes.recaptcha],
	[FieldComponentGroupTypes.checkboxes]: [FieldComponentTypes.checkbox, FieldComponentTypes.checkbox_group, FieldComponentTypes.gdpr],
	[FieldComponentGroupTypes.radios]: [FieldComponentTypes.radio],
	[FieldComponentGroupTypes.buttons]: [FieldComponentTypes.submit_button],
	[FieldComponentGroupTypes.images]: [BasicComponentTypes.image],
	[FieldComponentGroupTypes.spacers]: [BasicComponentTypes.spacer],
	[FieldComponentGroupTypes.socials]: [BasicComponentTypes.social_follow],
	[FieldComponentGroupTypes.texts]: [BasicComponentTypes.text],
};

export const FieldComponentNames = {
	submit_button: 'submit_button',
	email_input: 'email',
	phone_input: 'phone',
	text_input: 'text',
	longtext_input: 'long_text',
	singleselect_input: 'singleselect',
	multiselect_input: 'multiselect',
	date_input: 'date',
	number_input: 'number',
	radio: 'radio',
	checkbox: 'checkbox',
	checkbox_group: 'checkbox_group',
	gdpr: 'gdpr_checkbox',
	recaptcha: 'recaptcha',
	file_upload: 'file_upload',
};

export const SpecialComponentTypes = {
	fieldPlaceholder: 'fieldPlaceholder',
};

export const AllComponentTypes = {
	...BasicComponentTypes,
	...FieldComponentTypes,
};

export const LimitedComponentTypes = [AllComponentTypes.submit_button, AllComponentTypes.gdpr, AllComponentTypes.recaptcha];

export const slotSubstractor = {
	FULL: 1,
	ONE_THIRD: 3,
	ONE_FOURTH: 4,
	HALF: 2,
	TWO_THIRDS: 3 / 2,
};

export const slotDividers = {
	FULL: 1,
	ONE_THIRD: 3,
	ONE_FOURTH: 4,
	HALF: 2,
	TWO_THIRDS: 1.5,
};

export const ImageWidthLimitsUI = {
	FULL: {
		article: 199,
		image: 600,
		//described_image: 600
	},
	ONE_THIRD: {
		article: 67,
		image: 200,
		//described_image: 200
	},
	ONE_FOURTH: {
		article: 50,
		image: 150,
		//described_image: 200
	},
	TWO_THIRDS: {
		article: 133,
		image: 400,
		//described_image: 400
	},
	HALF: {
		article: 100,
		image: 300,
		described_image: 300,
	},
};

export const ImageWidthLimitsContent = {
	FULL: {
		article: 200,
		image: 600,
		//described_image: 600
	},
	ONE_THIRD: {
		article: 67,
		image: 200,
		//described_image: 200
	},
	ONE_FOURTH: {
		article: 50,
		image: 150,
		//described_image: 200
	},
	TWO_THIRDS: {
		article: 133,
		image: 400,
		//described_image: 400
	},
	HALF: {
		article: 100,
		image: 300,
		//described_image: 300
	},
};

export const SlotSpacingTypes = {
	SIDE: 'SIDE',
	CENTER: 'CENTER',
};

export const ModalTypes = {
	COMPONENT_ERROR_MODAL: 'COMPONENT_ERROR_MODAL',
	JSON_ERROR_MODAL: 'JSON_ERROR_MODAL',
	STRUCTURE_MODAL: 'STRUCTURE_MODAL',
	SELECT_TEMPLATE_MODAL: 'SELECT_TEMPLATE_MODAL',
	ELEMENT_MODAL: 'ELEMENT_MODAL',
	GENERAL_MODAL: 'GENERAL_MODAL',
	TEMPLATE_PREVIEW_MODAL: 'TEMPLATE_PREVIEW_MODAL',
	DONT_ASK: 'DONT_ASK',
	FORM_SETTINGS_MODAL: 'FORM_SETTINGS_MODAL',
	GOOGLE_FONTS_MODAL: 'GOOGLE_FONTS_MODAL',
	CROP_MODAL: 'CROP_MODAL',
	IMAGE_PICKER: 'IMAGE_PICKER',
	PRODUCT: 'PRODUCT',
	FILE_UPLOAD: 'FILE_UPLOAD',
};

export const Modals = {
	CONFIRM: 'CONFIRM',
	INFO: 'INFO',
	ERROR: 'ERROR',
	WARNING: 'WARNING',
};

export const ModalDesigns = {
	[Modals.CONFIRM]: {
		iconColor: 'info.500',
		icon: mdiAlertCircle,
		title: 'Confirm',
	},
	[Modals.INFO]: {
		iconColor: 'info.500',
		icon: mdiAlertCircle,
		title: 'Important information',
	},
	[Modals.WARNING]: {
		iconColor: 'orange.300',
		icon: mdiAlertCircle,
		title: 'Warning',
	},
	[Modals.ERROR]: {
		iconColor: 'danger.500',
		icon: mdiAlertCircle,
		title: 'Error',
	},
};

export const ElementLabels = {
	[SpecialComponentTypes.fieldPlaceholder]: strings.fieldPlaceholder,
	[AllComponentTypes.radio]: strings.radio,
	[AllComponentTypes.checkbox]: strings.checkbox,
	[AllComponentTypes.checkbox_group]: strings.checkbox_group,
	[AllComponentTypes.gdpr]: strings.gdpr,
	[AllComponentTypes.recaptcha]: strings.recaptcha,
	[AllComponentTypes.email_input]: strings.email_input,
	[AllComponentTypes.phone_input]: strings.phone_input,
	[AllComponentTypes.text_input]: strings.text_input,
	[AllComponentTypes.longtext_input]: strings.longtext_input,
	[AllComponentTypes.singleselect_input]: strings.singleselect_input,
	[AllComponentTypes.multiselect_input]: strings.multiselect_input,
	[AllComponentTypes.date_input]: strings.date_input,
	[AllComponentTypes.number_input]: strings.number_input,
	[AllComponentTypes.file_upload]: strings.file_upload,
	[AllComponentTypes.image]: strings.image,
	[AllComponentTypes.text]: strings.text,
	[AllComponentTypes.submit_button]: strings.submit_button,
	[AllComponentTypes.article]: strings.article,
	[AllComponentTypes.spacer]: strings.spacer,
	[AllComponentTypes.social_follow]: strings.social_follow,
	[AllComponentTypes.coupon]: strings.coupon,
	//[AllComponentTypes.described_image]: 'Described Image',
	[AllComponentTypes.html]: strings.html,
	[AllComponentTypes.timer]: strings.timer,
	[AllComponentTypes.video]: strings.video,
	[AllComponentTypes.form]: strings.form,
	[AllComponentTypes.product]: strings.product,
};

export const ElementMenuIcons = {
	[AllComponentTypes.radio]: radioIcon,
	[AllComponentTypes.checkbox]: checkboxIcon,
	[AllComponentTypes.checkbox_group]: checkboxesIcon,
	[AllComponentTypes.gdpr]: gdprIcon,
	[AllComponentTypes.recaptcha]: recaptchaIcon,
	[AllComponentTypes.email_input]: emailIcon,
	[AllComponentTypes.phone_input]: phoneIcon,
	[AllComponentTypes.text_input]: shortTextIcon,
	[AllComponentTypes.longtext_input]: longTextIcon,
	[AllComponentTypes.singleselect_input]: singleSelectIcon,
	[AllComponentTypes.multiselect_input]: multiSelectIcon,
	[AllComponentTypes.date_input]: dateIcon,
	[AllComponentTypes.number_input]: numberIcon,
	[AllComponentTypes.spacer]: spacerIcon,
	[AllComponentTypes.submit_button]: submitButtonIcon,
	[AllComponentTypes.file_upload]: fileUploadIcon,
	[AllComponentTypes.image]: imageIcon,
	[AllComponentTypes.text]: textIcon,
	[AllComponentTypes.article]: articleIcon,
	//[AllComponentTypes.described_image]: 'Described Image',
	[AllComponentTypes.html]: htmlIcon,
	[AllComponentTypes.social_follow]: socialFollowIcon,
	[AllComponentTypes.coupon]: couponIcon,
	[AllComponentTypes.timer]: timerIcon,
	[AllComponentTypes.video]: videoIcon,
	[AllComponentTypes.form]: formIcon,
	[AllComponentTypes.product]: productIcon,
};

export const ElementHelperGridIcons = {
	[AllComponentTypes.radio]: formIconGrid,
	[AllComponentTypes.checkbox]: formIconGrid,
	[AllComponentTypes.checkbox_group]: formIconGrid,
	[AllComponentTypes.gdpr]: formIconGrid,
	[AllComponentTypes.recaptcha]: formIconGrid,
	[AllComponentTypes.email_input]: formIconGrid,
	[AllComponentTypes.phone_input]: formIconGrid,
	[AllComponentTypes.text_input]: formIconGrid,
	[AllComponentTypes.longtext_input]: formIconGrid,
	[AllComponentTypes.singleselect_input]: formIconGrid,
	[AllComponentTypes.multiselect_input]: formIconGrid,
	[AllComponentTypes.date_input]: formIconGrid,
	[AllComponentTypes.number_input]: formIconGrid,
	[AllComponentTypes.file_upload]: formIconGrid,
	[AllComponentTypes.submit_button]: formIconGrid,
	[AllComponentTypes.spacer]: spacerIconGrid,
	[AllComponentTypes.submit_button]: buttonIconGrid,
	[AllComponentTypes.image]: imageIconGrid,
	[AllComponentTypes.text]: textIconGrid,
	[AllComponentTypes.article]: articleIconGrid,
	//[AllComponentTypes.described_image]: 'Described Image',
	[AllComponentTypes.html]: htmlIconGrid,
	[AllComponentTypes.social_follow]: socialFollowIconGrid,
	[AllComponentTypes.coupon]: couponIconGrid,
	[AllComponentTypes.timer]: timerIconGrid,
	[AllComponentTypes.video]: videoIconGrid,
	[AllComponentTypes.form]: formIconGrid,
	[AllComponentTypes.product]: productIconGrid,
};

export const DeprecatedComponentTypes = {
	described_image: 'described_image',
};

//Sidebar items
export const toggleConstants = {
	STRUCTURE: 'STRUCTURE',
	ELEMENTS: 'ELEMENTS',
	SETTINGS: 'SETTINGS',
	SCRIPT: 'SCRIPT',
	SEND_TEST: 'SEND_TEST',
	SAVE_TEMPLATE: 'SAVE_TEMPLATE',
	DRAFTS: 'DRAFTS',
	SAVE_CUSTOM_ROW: 'SAVE_CUSTOM_ROW',
	CODE_OPTIONS: 'CODE_OPTIONS',
};

export const CouponOptions = [
	{
		value: 'code',
		label: 'Random code per recipient',
	},
	{
		value: 'field',
		label: 'Custom field value',
	},
];

export const SlotMappings = {
	[RowTypes.FULL]: [SlotTypes.FULL],
	[RowTypes.HALF]: [SlotTypes.HALF, SlotTypes.HALF],
	[RowTypes.TWO_THIRDS_LEFT]: [SlotTypes.TWO_THIRDS, SlotTypes.ONE_THIRD],
	[RowTypes.TWO_THIRDS_RIGHT]: [SlotTypes.ONE_THIRD, SlotTypes.TWO_THIRDS],
	[RowTypes.THREE_ONE_THIRDS]: [SlotTypes.ONE_THIRD, SlotTypes.ONE_THIRD, SlotTypes.ONE_THIRD],
	[RowTypes.FOUR_ONE_FOURTHS]: [SlotTypes.ONE_FOURTH, SlotTypes.ONE_FOURTH, SlotTypes.ONE_FOURTH, SlotTypes.ONE_FOURTH],
};

export const VerticalAlignOptions = [
	{
		value: 'top',
		label: 'Top',
	},
	{
		value: 'middle',
		label: 'Middle',
	},
	{
		value: 'bottom',
		label: 'Bottom',
	},
];

//Content view menu items
export const contentViewMenuItemConstants = {
	CODE_VIEW: 'CODE_VIEW',
	DESIGN_VIEW: 'DESIGN_VIEW',
	SPLIT_VIEW: 'SPLIT_VIEW',
	MOBILE_VIEW: 'MOBILE_VIEW',
};

export const mobileRevertTypes = {
	ALL: 'ALL',
	GENERAL_SETTINGS: 'GENERAL_SETTINGS',
	ROW_SETTINGS: 'ROW_SETTINGS',
	ROW_ORDER: 'ROW_ORDER',
	SELECTED_ROW_SETTINGS: 'SELECTED_ROW_SETTINGS',
	SELECTED_ROW_ORDER: 'SELECTED_ROW_ORDER',
	SELECTED_ELEMENT_SETTINGS: 'SELECTED_ELEMENT_SETTINGS',
};

export const error_types = {
	ERROR: 'ERROR',
	WARNING: 'WARNING',
	INFO: 'INFO',
};

export const baseFieldStyles = {
	background_color: 'transparent',
	labelFont: 'Arial, Helvetica, sans-serif',
	labelFontSize: 16,
	labelFontColour: '#404040',
	labelPosition: 'column',
	labelTextDecoration: 'none',
	labelFontStyle: 'normal',
	labelFontWeight: 'bold',
	labelWidth: 24,
	asteriskColor: 'rgba(240, 52, 52, 1)',
	placeholderFont: 'Arial, Helvetica, sans-serif',
	placeholderFontSize: 16,
	placeholderFontColour: 'rgba(0,0,0,0.36)',
	placeholderFontWeight: 'normal',
	placeholderTextDecoration: 'none',
	placeholderFontStyle: 'normal',
	fieldFontWeight: 'normal',
	fieldTextDecoration: 'none',
	fieldFontStyle: 'normal',
	fieldFont: 'Arial, Helvetica, sans-serif',
	fieldFontSize: 16,
	fieldFontColour: 'rgba(0, 0, 0, 1)',
	fieldBorderWidth: 1,
	fieldBorderColour: 'rgba(0,0,0,0.42)',
	fieldBorderRadius: 6,
	fieldPadding: 10,
	fieldBgColor: 'rgba(255,255,255,1)',
	descriptionFontColour: 'rgba(0,0,0,0.55)',
	descriptionFontSize: 14,
	descriptionFontFamily: 'Arial, Helvetica, sans-serif',
	descriptionSpacing: 8,
	descriptionHide: false,
	descriptionTextDecoration: 'none',
	descriptionFontStyle: 'normal',
	descriptionFontWeight: 'normal',
};

export const baseRadioStyles = {
	...baseFieldStyles,
	fieldBorderRadius: 17,
	fieldFontColour: 'rgba(0,0,0,1)',
	fieldFontSize: 14,
	fieldPadding: 9,
	fieldBorderWidth: 2,
};

export const baseCheckboxStyles = {
	...baseFieldStyles,
	fieldBorderRadius: 2,
	fieldBorderWidth: 2,
	fieldFontSize: 20,
	fieldPadding: 10,
};

export const baseRecaptchaStyles = {
	background_color: 'transparent',
	alignment: 'center',
	theme: 'light',
	padding: 10,
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
};

export const baseButtonStyles = {
	background_color: 'transparent',
	color: 'rgba(63,135,239,1)',
	text_color: 'rgba(255,255,255,1)',
	alignment: 'center',
	customSize: {
		height: 100,
		width: 50,
	},
	padding: 16,
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
	margin: 16,
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontWeight: 'bold',
	textDecoration: 'none',
	fontStyle: 'normal',
	fontSize: 16,
	width: 'auto',
	borderRadius: 10,
	borderWidth: 0,
	borderColor: '#000000',
};

export const baseBackButtonStyles = {
	background_color: 'transparent',
	color: 'transparent',
	text_color: 'rgba(59,59,59,1)',
	padding: 0,
	alignment: 'center',
	customSize: {
		height: 100,
		width: 50,
	},
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
	margin: 16,
	fontFamily: 'Arial, Helvetica, sans-serif',
	fontWeight: 'bold',
	textDecoration: 'none',
	fontStyle: 'normal',
	fontSize: 16,
	width: 'auto',
	borderRadius: 10,
	borderWidth: 0,
	borderColor: '#000000',
};

export const baseImageStyles = {
	background_color: 'transparent',
	imageBorderRadius: 0,
	imageBorderWidth: 0,
	imageBorderColor: '#000000',
	padding: 0,
	multiPadding: {
		allow: false,
		paddingTop: 0,
		paddingRight: 0,
		paddingBottom: 0,
		paddingLeft: 0,
	},
	alignment: 'left',
};

export const baseTextStyles = {
	background_color: 'transparent',
	textBorderRadius: 0,
	textBorderWidth: 0,
	textBorderColor: '#000000',
	padding: 10,
	multiPadding: {
		allow: false,
		paddingTop: 10,
		paddingRight: 10,
		paddingBottom: 10,
		paddingLeft: 10,
	},
};

export const baseSocialStyles = {
	background_color: 'transparent',
	spacing: 2,
	alignment: 'center',
};

export const baseSpacerStyles = {
	borderWidth: 0,
	borderRadius: 0,
	borderColor: '#000000',
	height: 40,
	background_color: 'transparent',
};

const formInputDefaults = {
	id: '',
	type: '',
	name: '',
	background_color: 'transparent',
	...baseFieldStyles,
	width: 100,
	padding: 10,
	label: '',
	required: false,
	hidden: false,
	name: '',
	prefill: undefined,
	hiddenField: false,
	placeholder: '',
	disabled: false,
	value: '',
	readOnly: false,
	description: 'Help text…',
	optionsSpacing: 10,
	padding: 16,
	multiPadding: {
		allow: false,
		paddingTop: 16,
		paddingRight: 16,
		paddingBottom: 16,
		paddingLeft: 16,
	},
	pattern: '',
	mobileProps: {},
};

export const ComponentDefaults = {
	fieldPlaceholder: {
		id: '',
		type: SpecialComponentTypes.fieldPlaceholder,
	},
	recaptcha: {
		...baseRecaptchaStyles,
		id: '',
		type: AllComponentTypes.recaptcha,
		siteKey: '',
		mobileProps: {},
	},
	radio: {
		...formInputDefaults,
		...baseRadioStyles,
		labelWidth: 32,
		hasCustomValues: false,
		type: AllComponentTypes.radio,
		label: 'Radio button',
		name: FieldComponentNames.radio,
		placeholder: 'Radio button',
		labelWidth: 30,
		options: [
			{ value: 'Option 1', label: 'Option 1' },
			{ value: 'Option 2', label: 'Option 2' },
			{ value: 'Option 3', label: 'Option 3' },
		],
	},
	checkbox: {
		...formInputDefaults,
		...baseCheckboxStyles,
		type: AllComponentTypes.checkbox,
		labelWidth: 32,
		label: 'Checkbox',
		name: FieldComponentNames.checkbox,
		placeholder: 'Checkbox',
	},
	gdpr: {
		...formInputDefaults,
		type: AllComponentTypes.gdpr,
		label: 'I accept …',
		name: FieldComponentNames.gdpr,
		placeholder: 'Checkbox',
		legalText: '',
		legalLabel: 'I accept terms',
		required: true,
	},
	checkbox_group: {
		...formInputDefaults,
		...baseCheckboxStyles,
		hasCustomValues: false,
		type: AllComponentTypes.checkbox_group,
		labelWidth: 32,
		label: 'Checkbox Group',
		name: FieldComponentNames.checkbox_group,
		optionsLimit: 0,
		placeholder: 'Checkbox',
		options: [
			{ value: 'Option 1', label: 'Option 1' },
			{ value: 'Option 2', label: 'Option 2' },
			{ value: 'Option 3', label: 'Option 3' },
		],
	},
	phone_input: {
		...formInputDefaults,
		type: AllComponentTypes.phone_input,
		label: 'Phone',
		name: FieldComponentNames.phone_input,
		placeholder: '',
	},
	email_input: {
		...formInputDefaults,
		type: AllComponentTypes.email_input,
		label: 'Email',
		name: FieldComponentNames.email_input,
		placeholder: 'Enter email',
	},
	text_input: {
		...formInputDefaults,
		maxLength: 200,
		type: AllComponentTypes.text_input,
		label: 'Short text',
		name: FieldComponentNames.text_input,
		placeholder: 'Enter text',
	},
	longtext_input: {
		...formInputDefaults,
		maxLength: 1000,
		type: AllComponentTypes.longtext_input,
		label: 'Long text',
		name: FieldComponentNames.longtext_input,
		placeholder: 'Enter text',
	},
	singleselect_input: {
		...formInputDefaults,
		hasCustomValues: false,
		type: AllComponentTypes.singleselect_input,
		label: 'Select',
		name: FieldComponentNames.singleselect_input,
		placeholder: 'Select option',
		options: [
			{ value: 'Option 1', label: 'Option 1' },
			{ value: 'Option 2', label: 'Option 2' },
			{ value: 'Option 3', label: 'Option 3' },
		],
	},
	multiselect_input: {
		...formInputDefaults,
		hasCustomValues: false,
		type: AllComponentTypes.multiselect_input,
		label: 'Select',
		name: FieldComponentNames.multiselect_input,
		placeholder: 'Select options',
		optionsLimit: 0,
		options: [
			{ value: 'Option 1', label: 'Option 1' },
			{ value: 'Option 2', label: 'Option 2' },
			{ value: 'Option 3', label: 'Option 3' },
		],
	},
	date_input: {
		...formInputDefaults,
		type: AllComponentTypes.date_input,
		label: 'Date',
		name: FieldComponentNames.date_input,
		placeholder: '',
	},
	number_input: {
		...formInputDefaults,
		type: AllComponentTypes.number_input,
		label: 'Number',
		name: FieldComponentNames.number_input,
		placeholder: 'Enter number',
	},
	file_upload: {
		...formInputDefaults,
		descriptionFontStyle: 'normal',
		type: AllComponentTypes.file_upload,
		label: 'Upload files',
		name: FieldComponentNames.file_upload,
		fileSizeLimit: 5242880,
		fileSizeLimitUnit: 'MB',
		description: 'You can upload a maximum of [count] files, each with a size limit of [size]',
		maxFiles: 1,
		acceptedFileTypes: [],
	},
	image: {
		id: '',
		type: 'image',
		resized: false,
		resizeWidth: null,
		resizeHeight: null,
		originalWidth: 600,
		currentSrc: '',
		originalSrc: '',
		link_url: '',
		src: '',
		alt: '',
		...baseImageStyles,
		mobileProps: {},
	},
	text: {
		id: '',
		type: 'text',
		text: '',
		...baseTextStyles,
		mobileProps: {},
	},
	submit_button: {
		id: '',
		type: AllComponentTypes.submit_button,
		noLink: true,
		...baseButtonStyles,
		text: 'Submit',
		url: '',
		newWindow: true,
		back_button: {
			text: 'Back',
			...baseBackButtonStyles,
			newWindow: true,
		},
		mobileProps: {},
	},
	social_follow: {
		id: '',
		type: 'social_follow',
		...baseSocialStyles,
		style: 'default',
		facebook: '',
		twitter: '',
		instagram: '',
		pinterest: null,
		googleplus: null,
		youtube: null,
		linkedin: null,
		tumblr: null,
		deprecatedUsed: [],
		mobileProps: {},
	},
	spacer: {
		id: '',
		type: AllComponentTypes.spacer,
		...baseSpacerStyles,
		mobileProps: {},
	},
};

export const CustomFieldTypes = [
	'text',
	'number',
	'date',
	'dropdown',
	'email',
	'checkbox',
	// 'datetime'
];

export const FormSubmitOptions = [
	{
		value: 'message',
		label: 'Display success/error message',
	},
	{
		value: 'redirect',
		label: 'Redirect to page',
	},
	{
		value: 'redirectExternal',
		label: 'Redirect to external URL',
	},
];

export const FormSubmitOptionsWithExtras = [
	...FormSubmitOptions,
	{
		value: 'close',
		label: 'Close Form',
	},
];

export const FormLabelPositions = [
	{
		value: 'column',
		label: 'Top',
	},
	{
		value: 'row',
		label: 'Left',
	},
];

export const ScriptTypes = {
	head: 'head',
	body: 'body',
};

export const LanguageOptions = [
	{
		value: 'en',
		label: 'English',
	},
	{
		value: 'el',
		label: 'Greek',
	},
];

export const TimerOptions = [
	// {
	//     value: 'retro',
	//     label: 'Retro Analog flipping numbers',
	// },
	{
		value: 'plain',
		label: 'Plain',
	},
	{
		value: 'boxes',
		label: 'Boxes',
	},
];

export const FontFamilyOptions = [
	{
		value: 'Arial, Helvetica, sans-serif',
		label: 'Arial',
		timer_value: 'arial',
	},
	{
		value: '"Arial Black", Gadget, sans-serif',
		label: 'Arial Black',
		timer_value: 'arial-black',
	},
	{
		value: '"Bookman Old Style", serif',
		label: 'Bookman Old Style',
		timer_value: 'bookman-old-style',
	},
	{
		value: '"Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", cursive, sans-serif',
		label: 'Comic Sans MS',
		timer_value: 'comic-sans',
	},
	{
		value: 'Courier, monospace',
		label: 'Courier',
	},
	{
		value: '"Courier New", Courier, monospace',
		label: 'Courier New',
		timer_value: 'courier-new',
	},
	{
		value: 'Garamond, serif',
		label: 'Garamond',
		timer_value: 'garamond',
	},
	{
		value: 'Georgia, serif',
		label: 'Georgia',
		timer_value: 'georgia',
	},
	{
		value: 'Impact, Charcoal, sans-serif',
		label: 'Impact',
		timer_value: 'impact',
	},
	{
		value: '"Lucida Console", Monaco, monospace',
		label: 'Lucida Console',
		timer_value: 'lucida-console',
	},
	{
		value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
		label: 'Lucida Sans Unicode',
		timer_value: 'lucida-sans-unicode',
	},
	{
		value: 'Tahoma, Geneva, sans-serif',
		label: 'Tahoma',
		timer_value: 'tahoma',
	},
	{
		value: '"MS Sans Serif", Geneva, sans-serif',
		label: 'MS Sans Serif',
		timer_value: 'microsoft-sans-serif',
	},
	{
		value: '"MS Serif", "New York", sans-serif',
		label: 'MS Serif',
	},
	{
		value: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
		label: 'Palatino Linotype',
		timer_value: 'palatino-linotype',
	},
	{
		value: '"Times New Roman", Times, serif',
		label: 'Times New Roman',
		timer_value: 'times-new-roman',
	},
	{
		value: '"Trebuchet MS", Helvetica, sans-serif',
		label: 'Trebuchet MS',
		timer_value: 'trebuchet',
	},
	{
		value: 'Verdana, Geneva, sans-serif',
		label: 'Verdana',
		timer_value: 'verdana',
	},
	{
		value: 'Webdings, sans-serif',
		label: 'Webdings',
	},
	{
		value: 'Wingdings "Zapf Dingbats", sans-serif',
		label: 'Wingdings',
	},
];

export const TimerFontFamilyOptions = [
	...FontFamilyOptions,
	{
		value: 'Helvetica LT Condensed Bold',
		label: 'Helvetica LT Bold',
		timer_value: 'helvetica-lT-std',
	},
];

export const GoogleFontOptions = [
	{
		value: 'Roboto, sans-serif',
		label: 'Roboto',
		embed: 'Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i',
	},
	{
		value: 'Open Sans, sans-serif',
		label: 'Open Sans',
		embed: 'Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i',
	},
	{
		value: 'Lato, sans-serif',
		label: 'Late',
		embed: 'Lato:100,100i,300,300i,400,400i,700,700i,900,900i',
	},
	{
		value: 'Montserrat, sans-serif',
		label: 'Montserrat',
		embed: 'Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
	},
	{
		value: 'Roboto Condensed, sans-serif',
		label: 'Roboto Condensed',
		embed: 'Roboto+Condensed:300,300i,400,400i,700,700i',
	},
	{
		value: 'Source Sans Pro, sans-serif',
		label: 'Source Sans Pro',
		embed: 'Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i',
	},
	{
		value: 'Oswald, sans-serif',
		label: 'Oswald',
		embed: 'Oswald:200,300,400,500,600,700',
	},
	{
		value: 'Raleway, sans-serif',
		label: 'Raleway',
		embed: 'Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
	},
	{
		value: 'Merriweather, serif',
		label: 'Merriweather',
		embed: 'Merriweather:300,300i,400,400i,700,700i,900,900i',
	},
	{
		value: 'Roboto Slab, serif',
		label: 'Roboto Slab',
		embed: 'Roboto+Slab:100,300,400,700',
	},
	{
		value: 'PT Sans, sans-serif',
		label: 'PT Sans',
		embed: 'PT+Sans:400,400i,700,700i',
	},
	{
		value: 'Slabo 27px, serif',
		label: 'Slabo',
		embed: 'Slabo+27px',
	},
	{
		value: 'Poppins, sans-serif',
		label: 'Poppins',
		embed: 'Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
	},
	{
		value: 'Ubuntu, sans-serif',
		label: 'Ubuntu',
		embed: 'Ubuntu:300,300i,400,400i,500,500i,700,700i',
	},
	{
		value: 'Noto Sans, sans-serif',
		label: 'Noto Sans',
		embed: 'Noto+Sans:400,400i,700,700i',
	},
	{
		value: 'Heebo, sans-serif',
		label: 'Heebo',
		embed: 'Heebo:100,300,400,500,700,800,900',
	},
	{
		value: 'Open Sans Condensed, sans-serif',
		label: 'Open Sans Condensed',
		embed: 'Open+Sans+Condensed:300,300i,700',
	},
	{
		value: 'Playfair Display, serif',
		label: 'Playfair Display',
		embed: 'Playfair+Display:400,400i,700,700i,900,900i',
	},
	{
		value: 'Lora, serif',
		label: 'Lora',
		embed: 'Lora:400,400i,700,700i',
	},
	{
		value: 'PT Serif, serif',
		label: 'PT Serif',
		embed: 'PT+Serif:400,400i,700,700i',
	},
	{
		value: 'Muli, sans-serif',
		label: 'Muli',
		embed: 'Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i',
	},
	{
		value: 'Titillium Web, sans-serif',
		label: 'Titillium Web',
		embed: 'Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900',
	},
	{
		value: 'Roboto Mono, monospace',
		label: 'Roboto Mono',
		embed: 'Roboto+Mono:100,100i,300,300i,400,400i,500,500i,700,700i',
	},
	{
		value: 'Nunito, sans-serif',
		label: 'Nunito',
		embed: 'Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i',
	},
	{
		value: 'Rubik, sans-serif',
		label: 'Rubik',
		embed: 'Rubik:300,300i,400,400i,500,500i,700,700i,900,900i',
	},
	{
		value: 'Fira Sans, sans-serif',
		label: 'Fira Sans',
		embed: 'Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
	},
	{
		value: 'PT Sans Narrow, sans-serif',
		label: 'PT Sans Narrow',
		embed: 'PT+Sans+Narrow:400,700',
	},
	{
		value: 'Mukta, sans-serif',
		label: 'Mukta',
		embed: 'Mukta:200,300,400,500,600,700,800',
	},
	{
		value: 'Noto Serif, serif',
		label: 'Noto Serif',
		embed: 'Noto+Serif:400,400i,700,700i',
	},
	{
		value: 'Arimo, sans-serif',
		label: 'Arimo',
		embed: 'Arimo:400,400i,700,700i',
	},
	{
		value: 'Work Sans, sans-serif',
		label: 'Work Sans',
		embed: 'Work+Sans:100,200,300,400,500,600,700,800,900',
	},
	{
		value: 'Nanum Gothic, sans-serif',
		label: 'Nanum Gothic',
		embed: 'Nanum+Gothic:400,700,800',
	},
	{
		value: 'Inconsolata, monospace',
		label: 'Inconsolata',
		embed: 'Inconsolata:400,700',
	},
	{
		value: 'Quicksand, sans-serif',
		label: 'Quicksand',
		embed: 'Quicksand:300,400,500,700',
	},
	{
		value: 'Noto Sans KR, sans-serif',
		label: 'Noto Sans KR',
		embed: 'Noto+Sans+KR:100,300,400,500,700,900',
	},
	{
		value: 'Dosis, sans-serif',
		label: 'Dosis',
		embed: 'Dosis:200,300,400,500,600,700,800',
	},
	{
		value: 'Noto Sans JP, sans-serif',
		label: 'Noto Sans JP',
		embed: 'Noto+Sans+JP:100,300,400,500,700,900',
	},
	{
		value: 'Oxygen, sans-serif',
		label: 'Oxygen',
		embed: 'Oxygen:300,400,700',
	},
	{
		value: 'Hind, sans-serif',
		label: 'Hind',
		embed: 'Hind:300,400,500,600,700',
	},
	{
		value: 'Crimson Text, serif',
		label: 'Crimson Text',
		embed: 'Crimson+Text:400,400i,600,600i,700,700i',
	},
	{
		value: 'Bitter, serif',
		label: 'Bitter',
		embed: 'Bitter',
	},
	{
		value: 'Indie Flower, cursive',
		label: 'Indie Flower',
		embed: 'Indie+Flower',
	},
	{
		value: 'Libre Baskerville, serif',
		label: 'Libre Baskerville',
		embed: 'Libre+Baskerville:400,400i,700',
	},
	{
		value: 'Cabin, sans-serif',
		label: 'Cabin',
		embed: 'Cabin:400,400i,500,500i,600,600i,700,700i',
	},
	{
		value: 'Anton, sans-serif',
		label: 'Anton',
		embed: 'Anton',
	},
	{
		value: 'Josefin Sans, sans-serif',
		label: 'Josefin Sans',
		embed: 'Josefin+Sans:100,100i,300,300i,400,400i,600,600i,700,700i',
	},
	{
		value: 'Nunito Sans, sans-serif',
		label: 'Nunito Sans',
		embed: 'Nunito+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i',
	},
	{
		value: 'Libre Franklin, sans-serif',
		label: 'Libre Franklin',
		embed: 'Libre+Franklin:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i',
	},
	{
		value: 'Fjalla One, sans-serif',
		label: 'Fjalla One',
		embed: 'Fjalla+One',
	},
	{
		value: 'Arvo, serif',
		label: 'Arvo',
		embed: 'Arvo:400,400i,700,700i',
	},
];

export const CkeditorFontNames =
	'Arial=Arial, Helvetica, sans-serif;' +
	'Arial Black="Arial Black", Gadget, sans-serif;' +
	'Bookman Old Style="Bookman Old Style", serif;' +
	'Comic Sans MS="Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", cursive, sans-serif;' +
	'Courier New="Courier New", Courier, monospace;' +
	'Courier=Courier, monospace;' +
	'Garamond=Garamond, serif;' +
	'Georgia=Georgia, serif;' +
	'Impact=Impact, Charcoal, sans-serif;' +
	'Lucida Console="Lucida Console", Monaco, monospace;' +
	'Lucida Sans Unicode=Lucida Sans Unicode, Lucida Grande, sans-serif;' +
	'MS Sans Serif="MS Sans Serif", Geneva, sans-serif;' +
	'MS Serif="MS Serif", "New York", sans-serif;' +
	'Palatino Linotype="Palatino Linotype", "Book Antiqua", Palatino, serif;' +
	'Tahoma=Tahoma, Geneva, sans-serif;' +
	'Times New Roman="Times New Roman", Times, serif;' +
	'Trebuchet MS="Trebuchet MS", Helvetica, sans-serif;' +
	'Webdings=Webdings, sans-serif;' +
	'Wingdings=Wingdings, "Zapf Dingbats", sans-serif;' +
	'Verdana=Verdana, Geneva, sans-serif;';

export const CkeditorFontNamesLP =
	'Arial=Arial, Helvetica, sans-serif;' +
	'Arial Black="Arial Black", Gadget, sans-serif;' +
	'Bookman Old Style="Bookman Old Style", serif;' +
	'Comic Sans MS="Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", cursive, sans-serif;' +
	'Courier New="Courier New", Courier, monospace;' +
	'Courier=Courier, monospace;' +
	'Garamond=Garamond, serif;' +
	'Georgia=Georgia, serif;' +
	'Impact=Impact, Charcoal, sans-serif;' +
	'Lucida Console="Lucida Console", Monaco, monospace;' +
	'Lucida Sans Unicode=Lucida Sans Unicode, Lucida Grande, sans-serif;' +
	'MS Sans Serif="MS Sans Serif", Geneva, sans-serif;' +
	'MS Serif="MS Serif", "New York", sans-serif;' +
	'Palatino Linotype="Palatino Linotype", "Book Antiqua", Palatino, serif;' +
	'Tahoma=Tahoma, Geneva, sans-serif;' +
	'Times New Roman="Times New Roman", Times, serif;' +
	'Trebuchet MS="Trebuchet MS", Helvetica, sans-serif;' +
	'Webdings=Webdings, sans-serif;' +
	'Wingdings=Wingdings, "Zapf Dingbats", sans-serif;' +
	'Verdana=Verdana, Geneva, sans-serif;' +
	'Roboto=Roboto, sans-serif;' +
	'Open Sans="Open Sans", sans-serif;' +
	'Lato=Lato, sans-serif;' +
	'Montserrat=Montserrat, sans-serif;' +
	'Roboto Condensed="Roboto Condensed", sans-serif;' +
	'Source Sans Pro="Source Sans Pro", sans-serif;' +
	'Oswald=Oswald, sans-serif;' +
	'Raleway=Raleway, sans-serif;' +
	'Merriweather=Merriweather, serif;' +
	'Roboto Slab="Roboto Slab", serif;' +
	'PT Sans="PT Sans", sans-serif;' +
	'Slabo 27px="Slabo 27px", serif;' +
	'Poppins=Poppins, sans-serif;' +
	'Ubuntu=Ubuntu, sans-serif;' +
	'Noto Sans="Noto Sans", sans-serif;' +
	'Heebo=Heebo, sans-serif;' +
	'Open Sans Condensed="Open Sans Condensed", sans-serif;' +
	'Playfair Display="Playfair Display", serif;' +
	'Lora=Lora, serif;' +
	'PT Serif="PT Serif", serif;' +
	'Muli=Muli, sans-serif;' +
	'Titillium Web="Titillium Web", sans-serif;' +
	'Roboto Mono="Roboto Mono", monospace;' +
	'Nunito=Nunito, sans-serif;' +
	'Rubik=Rubik, sans-serif;' +
	'Fira Sans="Fira Sans", sans-serif;' +
	'PT Sans Narrow="PT Sans Narrow", sans-serif;' +
	'Mukta=Mukta, sans-serif;' +
	'Noto Serif="Noto Serif", serif;' +
	'Arimo=Arimo, sans-serif;' +
	'Work Sans="Work Sans", sans-serif;' +
	'Nanum Gothic="Nanum Gothic", sans-serif;' +
	'Inconsolata=Inconsolata, monospace;' +
	'Quicksand=Quicksand, sans-serif;' +
	'Noto Sans KR="Noto Sans KR", sans-serif;' +
	'Dosis=Dosis, sans-serif;' +
	'Noto Sans JP="Noto Sans JP", sans-serif;' +
	'Oxygen=Oxygen, sans-serif;' +
	'Hind=Hind, sans-serif;' +
	'Crimson Text="Crimson Text", serif;' +
	'Bitter=Bitter, serif;' +
	'Indie Flower="Indie Flower", cursive;' +
	'Libre Baskerville="Libre Baskerville", serif;' +
	'Cabin=Cabin, sans-serif;' +
	'Anton=Anton, sans-serif;' +
	'Josefin Sans="Josefin Sans", sans-serif;' +
	'Nunito Sans="Nunito Sans", sans-serif;' +
	'Libre Franklin="Libre Franklin", sans-serif;' +
	'Fjalla One="Fjalla One", sans-serif;' +
	'Arvo=Arvo, serif';

export const CkeditorLineHeights =
	'1em;1.1em;1.2em;1.3em;1.4em;1.5em;1em;1.6em;1.7em;1.8em;1.9em;2.0em;2em;2.1em;2.2em;3.3em;2.4em;2.5em;2.6em;2.7em;2.8em;2.9em;3.0em;';
// export const CkeditorLineHeights = "10px;20px;30px;40px;50px;60px;70px;80px;90px;100px;";

export const NotificationTypes = {
	ERROR: 'ERROR',
	SUCCESS: 'SUCCESS',
	WARNING: 'WARNING',
	INFO: 'INFO',
	LOADING: 'LOADING',
};

export const GridTypes = {
	TOP: 'TOP',
	BOTTOM: 'BOTTOM',
};

export const GridOptions = {
	ENABLED: 'ENABLED',
	DISABLED: 'DISABLED',
};

export const FormTypesList = [
	{
		description: `Create a form which will sit inline, i.e. within your page's content.`,
		icon: inlineIcon,
		title: 'Inline',
		type: 1,
	},
	{
		description: `Create a form which will appear as a popup in the middle of your page.`,
		icon: popupIcon,
		title: 'Modal',
		type: 2,
	},
	{
		description: `Create a form which will appear at the bottom of your page's visible area as you scroll.`,
		icon: bottomStickyIcon,
		title: 'Sticky',
		type: 3,
	},
	{
		description: `Create a slim form which will at the top or bottom of your page.`,
		icon: rowIcon,
		title: 'Row',
		type: 4,
	},
	{
		description: `Create a form which will cover the entire page.`,
		icon: fullPageIcon,
		title: 'Full-Page',
		type: 5,
	},
];

export const MetadataCategories = {
	inline: {
		EDITOR_SAVED_TEMPLATES: 'INLINE_FORM_TEMPLATES',
		EDITOR_USER_DETAILS: 'INLINE_FORM_USER_DETAILS',
		EDITOR_SAVED_DRAFTS: 'INLINE_FORM_DRAFTS',
		EDITOR_SAVED_DRAFTS_AUTOSAVE: 'INLINE_FORM_DRAFTS_AUTOSAVE',
		EDITOR_SAVED_CUSTOM_STRUCTURES: 'INLINE_FORM_CUSTOM_STRUCTURES',
		EDITOR_SAVED_CUSTOM_ELEMENTS: 'INLINE_FORM_CUSTOM_ELEMENTS',
		EDITOR_SAVED_GENERIC_ENTRY: 'INLINE_FORM_GENERIC_ENTRY',
		EDITOR_SAVED_GLOBAL_STYLES: 'INLINE_FORM_GLOBAL_STYLES',
	},
};

export const FormFieldTypes = {
	field: 1100,
	label: 1176,
	placeholder: 1124,
	button: 1320,
	recaptcha: 1318,
	gdpr: 1450,
};

export const ProductFieldTypes = {
	image: 180,
	title: 250,
	description: 325,
	price: 395,
	oldPrice: 440,
	sku: 545,
	button: 615,
};

export const KeyboardKeys = {
	ENTER: 13,
	ESC: 27,
};

export const structureAlignmentOptions = [
	{
		label: 'Top',
		value: 'flex-start',
	},
	{
		label: 'Middle',
		value: 'center',
	},
];

export const FormAlignSettings = [
	{
		label: 'Left',
		value: 'flex-start',
	},
	{
		label: 'Center',
		value: 'center',
	},
	{
		label: 'Right',
		value: 'flex-end',
	},
];

export const PagePlaceholderProperties = {
	type: 'pagePlaceholder',
};

export const RowDefaultProperties = {
	background_image: '',
	background_image_type: '',
	background_repeat: 'no-repeat',
	background_position: 'initial',
	borderWidth: 0,
	borderColor: '#000000',
	borderRadius: 0,
	padding: 0,
	multiPadding: {
		allow: false,
		paddingTop: 0,
		paddingRight: 0,
		paddingBottom: 0,
		paddingLeft: 0,
	},
	pageIndex: 0,
	bg_color: 'transparent',
	hidden: false,
	responsive: false,
	behavior: 'NORMAL',
	slot_spacing_side: config.slot_spacing_side,
	slot_spacing_center: config.slot_spacing_center,
	mobileProps: {},
};

export const SlotDefaultProperties = {
	background_color: 'transparent',
	contentBgColor: 'transparent',
	borderWidth: 0,
	borderColor: '#000000',
	borderRadius: 0,
	padding: 0,
	hidden: false,
	id: '',
	multiPadding: {
		allow: false,
		paddingTop: 0,
		paddingRight: 0,
		paddingBottom: 0,
		paddingLeft: 0,
	},
	vertical_align: 'top',
	components: [],
	mobileProps: {},
};

export const GeneralSettingsDefaults = {
	general_styles: {
		text_line_height: '1.3',
		font_size: 16,
		font_family: 'Arial, Helvetica, sans-serif',
		color: '#000000',
		background_image: '',
		background_repeat: 'no-repeat',
		bg_color: 'rgba(0,0,0,0.1)',
		retina_images: false,
		borderWidth: 0,
		structureWidth: 600,
		borderColor: '#000000',
		border: {},
	},
	link_settings: {
		link_color: '#337ab7',
		link_font_family: 'Arial, Helvetica, sans-serif',
		link_font_size: 16,
		link_font_style: 'normal',
		link_font_weight: 'normal',
		link_text_decoration: 'none',
	},
};

export const GeneralSettingsDefaultsLP = {
	general_styles: {
		text_line_height: '1.3',
		font_size: 16,
		font_family: 'Arial, Helvetica, sans-serif',
		color: '#000000',
		background_image: '',
		background_repeat: 'no-repeat',
		bg_color: 'transparent',
		title: '',
		retina_images: false,
		borderWidth: 0,
		structureWidth: 1000,
		borderColor: '#000000',
		border: {},
	},
	link_settings: {
		link_color: '#337ab7',
		link_font_family: 'Arial, Helvetica, sans-serif',
		link_font_size: 16,
		link_font_style: 'normal',
		link_font_weight: 'normal',
		link_text_decoration: 'none',
	},
};

export const defaultColorPresets = [
	'#d0021b',
	'#f5a623',
	'#f8e71c',
	'#8b572a',
	'#7ed321',
	'#417505',
	'#bd10e0',
	'#9013fe',
	'#4a90e2',
	'#50e3c2',
	'#b8e986',
	'#000000',
	'#4a4a4a',
	'#9b9b9b',
	'#ffffff',
];

export const emptyBaseDefaultJson = {
	...GeneralSettingsDefaults.general_styles,
	editor3: true,
	usedColors: defaultColorPresets,
	rows: [],
	deletedComponents: [],
	script: {
		head: '// This code will be added to a <Script> tag, \n// at the <head> of the document \n',
		body: '// This code will be added to a <Script> tag, \n// at the end of the <body> element \n',
	},
};

export const FormSuccessActions = {
	none: 'none',
	message: 'message',
	redirectUrl: 'redirectUrl',
};

export const FormSuccessLabels = {
	none: 'None',
	message: 'Success message',
	redirectUrl: 'Redirect to a URL',
};

export const FormFailActions = {
	message: 'message',
};

export const validationMessages = {
	required: 'This field is required',
	email: 'Email address must follow the format user@example.com',
	phone: 'Phone number must follow the format +(country_code)(phone_number)',
	uploadFileType: 'File type not supported',
	uploadFileSize: 'Max file size exceeded',
	uploadFileCount: 'Max number of files exceeded',
	uploadFileGenericError: 'File did not upload',
};

export const ComparerTypes = {
	contains: 'contains',
	doesNotContain: 'does_not_contain',
	isEqualTo: 'is_equal_to',
	isNotEqualTo: 'is_not_equal_to',
	is: 'is',
	isNot: 'is_not',
	startsWith: 'starts_with',
	endsWith: 'ends_with',
	isEmpty: 'is_empty',
	isNotEmpty: 'is_not_empty',
	isGreaterThan: 'is_greater_than',
	isSmallerThan: 'is_smaller_than',
	isBetween: 'is_between',
	isBefore: 'is_before',
	isAfter: 'is_after',
	isChecked: 'is_checked',
	isNotChecked: 'is_not_checked',
};

export const ComparerTypesExpectedValue = {
	[ComparerTypes.contains]: 'string',
	[ComparerTypes.doesNotContain]: 'string',
	[ComparerTypes.isEqualTo]: 'string',
	[ComparerTypes.isNotEqualTo]: 'string',
	[ComparerTypes.is]: 'string',
	[ComparerTypes.isNot]: 'string',
	[ComparerTypes.startsWith]: 'string',
	[ComparerTypes.endsWith]: 'string',
	[ComparerTypes.isEmpty]: 'empty',
	[ComparerTypes.isNotEmpty]: 'empty',
	[ComparerTypes.isGreaterThan]: 'string',
	[ComparerTypes.isSmallerThan]: 'string',
	[ComparerTypes.isBetween]: 'object',
	[ComparerTypes.isBefore]: 'string',
	[ComparerTypes.isAfter]: 'string',
	[ComparerTypes.isChecked]: 'empty',
	[ComparerTypes.isNotChecked]: 'empty',
};

export const ComparerTypesExpectedValueByFieldType = {
	[FieldComponentTypes.multiselect_input]: {
		[ComparerTypes.isEqualTo]: 'array',
		[ComparerTypes.isNotEqualTo]: 'array',
	},

	[FieldComponentTypes.checkbox_group]: {
		[ComparerTypes.isEqualTo]: 'array',
		[ComparerTypes.isNotEqualTo]: 'array',
	},
};

export const ComparerTypesLabels = {
	[ComparerTypes.contains]: 'contains',
	[ComparerTypes.doesNotContain]: 'does not contain',
	[ComparerTypes.isEqualTo]: 'is equal to',
	[ComparerTypes.isNotEqualTo]: 'is not equal to',
	[ComparerTypes.is]: 'is',
	[ComparerTypes.isNot]: 'is not',
	[ComparerTypes.startsWith]: 'starts with',
	[ComparerTypes.endsWith]: 'ends with',
	[ComparerTypes.isEmpty]: 'is empty',
	[ComparerTypes.isNotEmpty]: 'is not empty',
	[ComparerTypes.isGreaterThan]: 'is greater than',
	[ComparerTypes.isSmallerThan]: 'is smaller than',
	[ComparerTypes.isBetween]: 'is between',
	[ComparerTypes.isBefore]: 'is before',
	[ComparerTypes.isAfter]: 'is after',
	[ComparerTypes.isChecked]: 'is checked',
	[ComparerTypes.isNotChecked]: 'is not checked',
};

const CommonTextInputComparers = [
	ComparerTypes.contains,
	ComparerTypes.doesNotContain,
	ComparerTypes.isEqualTo,
	ComparerTypes.isNotEqualTo,
	ComparerTypes.startsWith,
	ComparerTypes.endsWith,
	ComparerTypes.isEmpty,
	ComparerTypes.isNotEmpty,
];

export const ComponentTypeToComparer = {
	[FieldComponentTypes.text_input]: CommonTextInputComparers,

	[FieldComponentTypes.email_input]: CommonTextInputComparers,

	[FieldComponentTypes.phone_input]: CommonTextInputComparers,

	[FieldComponentTypes.longtext_input]: CommonTextInputComparers,

	[FieldComponentTypes.singleselect_input]: [
		ComparerTypes.isEqualTo,
		ComparerTypes.isNotEqualTo,
		ComparerTypes.isEmpty,
		ComparerTypes.isNotEmpty,
	],

	[FieldComponentTypes.radio]: [ComparerTypes.isEqualTo, ComparerTypes.isNotEqualTo, ComparerTypes.isEmpty, ComparerTypes.isNotEmpty],

	[FieldComponentTypes.multiselect_input]: [
		ComparerTypes.isEqualTo,
		ComparerTypes.isNotEqualTo,
		ComparerTypes.isEmpty,
		ComparerTypes.isNotEmpty,
	],

	[FieldComponentTypes.checkbox_group]: [
		ComparerTypes.isEqualTo,
		ComparerTypes.isNotEqualTo,
		ComparerTypes.isEmpty,
		ComparerTypes.isNotEmpty,
	],

	[FieldComponentTypes.number_input]: [
		ComparerTypes.isEqualTo,
		ComparerTypes.isNotEqualTo,
		ComparerTypes.contains,
		ComparerTypes.doesNotContain,
		ComparerTypes.startsWith,
		ComparerTypes.endsWith,
		ComparerTypes.isGreaterThan,
		ComparerTypes.isSmallerThan,
		ComparerTypes.isBetween,
		ComparerTypes.isEmpty,
		ComparerTypes.isNotEmpty,
	],

	[FieldComponentTypes.date_input]: [
		ComparerTypes.is,
		ComparerTypes.isNot,
		ComparerTypes.isBefore,
		ComparerTypes.isAfter,
		ComparerTypes.isBetween,
		ComparerTypes.isEmpty,
		ComparerTypes.isNotEmpty,
	],

	[FieldComponentTypes.checkbox]: [ComparerTypes.isChecked, ComparerTypes.isNotChecked],
};

export const ConditionalLogicTypes = {
	condition: 'condition',
	logic: 'logic',
	group: 'group',
	criteria: 'criteria',
	action: 'action',
};

export const ConditionsActionTypes = {
	hideField: 'hide_field',
	showField: 'show_field',
	mandatoryField: 'mandatory_field',
	optionalField: 'optional_field',
};

export const ConditionsActionTypeLabels = {
	[ConditionsActionTypes.hideField]: 'Hide field',
	[ConditionsActionTypes.showField]: 'Show field',
	[ConditionsActionTypes.mandatoryField]: 'Make a field required',
	[ConditionsActionTypes.optionalField]: 'Make a required field optional',
};

export const FieldsNotAllowedAsCondition = [AllComponentTypes.file_upload];

export const LogicErrors = {
	invalidField: 'invalid_field',
	missingOperator: 'missing_operator',
	missingSettings: 'missing_settings',
	missingComparer: 'missing_comparer',
	missingId: 'missing_id',
	missingType: 'missing_type',
	missingValue: 'missing_value',
	invalidValue: 'invalid_value',
	missingActions: 'missing_actions',
	missingAction: 'missing_action',
	missingName: 'missing_name',
};

export const BaseConditionProps = {
	id: '',
	type: ConditionalLogicTypes.condition,
	settings: [],
	operator: 'and',
};

export const BaseConditionGroupProps = {
	id: '',
	type: ConditionalLogicTypes.group,
	settings: [],
	operator: 'and',
};

export const BaseCriteriaProps = {
	id: '',
	type: ConditionalLogicTypes.criteria,
	comparer: '',
	value: '',
};

export const BaseLogicActionProps = {
	id: '',
	action: '',
};

export const BaseLogicProps = {
	type: ConditionalLogicTypes.logic,
	id: '',
	settings: [],
};

export const ConditionsMock = {
	logics: [
		{
			type: ConditionalLogicTypes.logic,
			id: 'logic-1',
			name: 'Logic 1',
			settings: [
				{
					id: 'condition-1',
					type: ConditionalLogicTypes.condition,
					field: {
						id: 'field-1',
						name: 'address',
						label: 'Address',
						type: 'text_input',
						page: 0,
					},
					settings: [
						{
							id: 'criteria-1',
							type: ConditionalLogicTypes.criteria,
							comparer: 'contains',
							value: 'Marousi',
						},
						{
							id: 'criteria-2',
							type: ConditionalLogicTypes.criteria,
							comparer: 'ends_with',
							value: 'ousi',
						},
					],
					operator: 'and',
				},
				{
					id: 'condition-2',
					type: ConditionalLogicTypes.condition,
					field: {
						id: 'field-2',
						name: 'email',
						label: 'Email',
						type: 'email_input',
						page: 0,
					},
					settings: [
						{
							id: 'criteria-3',
							type: ConditionalLogicTypes.criteria,
							comparer: 'contains',
							value: 'gmail',
						},
						{
							id: 'criteria-4',
							type: ConditionalLogicTypes.criteria,
							comparer: 'ends_with',
							value: '.com',
						},
					],
					operator: 'or',
				},
				{
					id: 'condition-3',
					type: ConditionalLogicTypes.condition,
					field: {
						id: 'field-3',
						name: 'name',
						label: 'Name',
						type: 'text_input',
						page: 1,
					},
					settings: [
						{
							id: 'criteria-5',
							type: ConditionalLogicTypes.criteria,
							comparer: 'contains',
							value: 'whatever',
						},

						{
							id: 'criteria-6',
							type: ConditionalLogicTypes.criteria,
							comparer: 'ends_with',
							value: 'ever',
						},
					],
					operator: 'and',
				},
				{
					id: 'group-1',
					type: ConditionalLogicTypes.group,
					settings: [
						{
							type: ConditionalLogicTypes.condition,
							id: 'condition-4',
							field: {
								id: 'field-4',
								name: 'dob',
								label: 'DOB',
								type: 'date_input',
								page: 0,
							},
							settings: [
								{
									id: 'criteria-7',
									type: ConditionalLogicTypes.criteria,
									comparer: 'is_before',
									value: '2025-02-07',
								},
								{
									id: 'criteria-8',
									type: ConditionalLogicTypes.criteria,
									comparer: 'is_after',
									value: '2024-02-14',
								},
							],
							operator: 'and',
						},
						{
							type: ConditionalLogicTypes.condition,
							id: 'condition-5',
							field: {
								id: 'field-5',
								name: 'date',
								label: 'Date',
								type: 'date_input',
								page: 0,
							},
							settings: [
								{
									id: 'criteria-6',
									type: ConditionalLogicTypes.criteria,
									comparer: 'is_between',
									value: {
										start: '2024-02-07',
										end: '2024-02-25',
									},
								},
							],
							operator: 'or',
						},
						{
							type: 'condition',
							id: 'condition-6',
							field: {
								id: 'field-6',
								name: 'date2',
								label: 'Date 2',
								type: 'date_input',
							},
							settings: [
								{
									id: 'criteria-4',
									type: 'criteria',
									comparer: 'is_equal_to',
									value: '2024-02-07',
								},
								{
									id: 'criteria-5',
									type: 'criteria',
									comparer: 'is_not_equal_to',
									value: '2024-02-07',
								},
								{
									id: 'criteria-6',
									type: 'criteria',
									comparer: 'is_after',
									value: '2024-02-12',
								},
								{
									id: 'criteria-7',
									type: 'criteria',
									comparer: 'is_before',
									value: '2024-02-27',
								},
								{
									id: 'criteria-8',
									type: 'criteria',
									comparer: 'is_empty',
								},
								{
									id: 'criteria-9',
									type: 'criteria',
									comparer: 'is_not_empty',
								},
							],
							operator: 'or',
						},
					],
					operator: 'and',
				},
				{
					type: 'condition',
					id: 'condition-test_input',
					field: {
						id: 'field-1',
						name: 'input 1',
						label: 'Number input',
						type: 'text_input',
					},
					settings: [
						{
							id: 'criteria-1',
							type: 'criteria',
							comparer: 'is_equal_to',
							value: '5',
						},
						{
							id: 'criteria-2',
							type: 'criteria',
							comparer: 'is_not_equal_to',
							value: '4',
						},
						{
							id: 'criteria-3',
							type: 'criteria',
							comparer: 'contains',
							value: '55',
						},
						{
							id: 'criteria-4',
							type: 'criteria',
							comparer: 'does_not_contain',
							value: '56',
						},
						{
							id: 'criteria-5',
							type: 'criteria',
							comparer: 'starts_with',
							value: '5',
						},
						{
							id: 'criteria-6',
							type: 'criteria',
							comparer: 'ends_with',
							value: '7',
						},
						{
							id: 'criteria-7',
							type: 'criteria',
							comparer: 'is_empty',
						},
						{
							id: 'criteria-8',
							type: 'criteria',
							comparer: 'is_not_empty',
						},
					],
					operator: 'or',
				},
				{
					type: 'condition',
					id: 'condition-number_input',
					field: {
						id: 'field-1',
						name: 'input 1',
						label: 'Number input',
						type: 'number_input',
					},
					settings: [
						{
							id: 'criteria-1',
							type: 'criteria',
							comparer: 'is_equal_to',
							value: '5',
						},
						{
							id: 'criteria-2',
							type: 'criteria',
							comparer: 'is_not_equal_to',
							value: '4',
						},
						{
							id: 'criteria-3',
							type: 'criteria',
							comparer: 'contains',
							value: '55',
						},
						{
							id: 'criteria-4',
							type: 'criteria',
							comparer: 'does_not_contain',
							value: '56',
						},
						{
							id: 'criteria-5',
							type: 'criteria',
							comparer: 'starts_with',
							value: '5',
						},
						{
							id: 'criteria-6',
							type: 'criteria',
							comparer: 'ends_with',
							value: '7',
						},
						{
							id: 'criteria-7',
							type: 'criteria',
							comparer: 'is_greater_than',
							value: '7',
						},
						{
							id: 'criteria-8',
							type: 'criteria',
							comparer: 'is_smaller_than',
							value: '100',
						},
						{
							id: 'criteria-9',
							type: 'criteria',
							comparer: 'is_between',
							value: { start: '0', end: '100' },
						},
					],
					operator: 'or',
				},
				{
					type: 'condition',
					id: 'condition-radio',
					field: {
						id: 'field-5',
						name: 'input 1',
						label: 'radio input',
						type: 'radio',
					},
					settings: [
						{
							id: 'criteria-1',
							type: 'criteria',
							comparer: 'is_equal_to',
							value: '2',
						},
						{
							id: 'criteria-2',
							type: 'criteria',
							comparer: 'is_not_equal_to',
							value: '3',
						},
						{
							id: 'criteria-3',
							type: 'criteria',
							comparer: 'is_empty',
						},
						{
							id: 'criteria-4',
							type: 'criteria',
							comparer: 'is_not_empty',
						},
					],
					operator: 'or',
				},
				{
					type: 'condition',
					id: 'condition-multiselect_input',
					field: {
						id: 'field-5',
						name: 'input 1',
						label: 'multiselect_input',
						type: 'multiselect_input',
					},
					settings: [
						{
							id: 'criteria-1',
							type: 'criteria',
							comparer: 'is_equal_to',
							value: ['1', '2'],
						},
						{
							id: 'criteria-2',
							type: 'criteria',
							comparer: 'is_not_equal_to',
							value: ['3'],
						},
						{
							id: 'criteria-3',
							type: 'criteria',
							comparer: 'is_empty',
						},
						{
							id: 'criteria-4',
							type: 'criteria',
							comparer: 'is_not_empty',
						},
					],
					operator: 'or',
				},
			],
			actions: [
				{
					action: 'hide_field',
					id: 'action-1',
					field: {
						id: 'field-4',
						name: 'dob',
						label: 'DOB',
						type: 'date_input',
						page: 0,
					},
				},
			],
		},
	],
};

export const emptyBaseDefaultJsonLP = {
	...GeneralSettingsDefaultsLP.general_styles,
	editor3: true,
	successAction: {
		type: FormSuccessActions.message,
		[FormSuccessActions.message]: `<p><span style="font-family: Arial, Helvetica, sans-serif; font-size: 16px;">Form has been submitted successfully.</span></p>`,
		[FormSuccessActions.redirectUrl]: 'https://',
	},
	failAction: {
		type: FormSuccessActions.message,
		[FormSuccessActions.message]: `<p><span style="font-family: Arial, Helvetica, sans-serif; font-size: 16px;">Form has not been submitted.</span></p>`,
		[FormSuccessActions.redirectUrl]: 'https://',
	},
	usedColors: defaultColorPresets,
	disableAutoComponents: [],
	rows: [],
	validation_messages: validationMessages,
	lastPage: 0,
	customCss: '',
	mobileProps: {},
	logics: [],
};

// export const emptyDefaultJson = {
// 	editor: emptyBaseDefaultJson,
// 	landingPage: emptyBaseDefaultJsonLP,
// 	popup: emptyBaseDefaultJsonLP,
// 	inline: emptyBaseDefaultJsonLP,
// 	scrollbox: emptyBaseDefaultJsonLP,
// 	smartbar: emptyBaseDefaultJsonLP,
// 	welcomemat: emptyBaseDefaultJsonLP,
// 	promotion: emptyBaseDefaultJsonLP,
// };

export const emptyDefaultJson = {
	inline: emptyBaseDefaultJsonLP,
};

export const IconTypes = {
	FONT_AWESOME: 'FONT_AWESOME',
	ELEGANT: 'ELEGANT',
};

export const PersonalizationTags = [
	{ label: 'Unsubscribe Link', title: 'Unsubscribe Link', value: '#unsubscribeLink#' },
	{ label: 'Update Profile Link', title: 'Update Profile Link', value: '#updateProfileLink#' },
	{ label: 'Forward to a Friend Link', title: 'Forward to a Friend Link', value: '#forwardToFriendLink#' },
	{ label: 'Double Opt-in Verification Link', title: 'Double Opt-in Verification Link', value: '#VerificationLink#' },
	{ label: 'Account VAT', title: 'Account VAT', value: '#account:vat#' },
	{ label: 'Account Email', title: 'Account Email', value: '#account:email#' },
	{ label: 'Account Address', title: 'Account Address', value: '#account:address#' },
	{ label: 'Account Company', title: 'Account Company', value: '#account:company#' },
	{ label: 'Current Date and Time', title: 'Current Date and Time', value: '#datetime#' },
	{ label: 'Recipient Name', title: 'Recipient Name', value: '#recipient:name| fallback#' },
	{ label: 'Recipient Email', title: 'Recipient Email', value: '#recipient:email#' },
	{ label: 'Recipient Mobile', title: 'Recipient Mobile', value: '#recipient:mobile#' },
	{ label: 'This email was sent to', title: 'This email was sent to', value: '#emailSentTo#' },
	{
		label: 'View in Browser',
		title: 'View in Browser',
		value:
			'<a href="#trackingDomain#/show_campaign/#campaign:key#/#recipient:key#/#ab#"><span class="custom-style-link">View in Browser</span></a>',
	},
];

export const PersonalizationTagsGeneric = [
	{ label: 'Double Opt-in Verification Link', title: 'Double Opt-in Verification Link', value: '#VerificationLink#' },
	{ label: 'Account VAT', title: 'Account VAT', value: '#account:vat#' },
	{ label: 'Account Email', title: 'Account Email', value: '#account:email#' },
	{ label: 'Account Address', title: 'Account Address', value: '#account:address#' },
	{ label: 'Account Company', title: 'Account Company', value: '#account:company#' },
	{ label: 'Current Date and Time', title: 'Current Date and Time', value: '#datetime#' },
	{ label: 'Recipient Name', title: 'Recipient Name', value: '#recipient:name| fallback#' },
	{ label: 'Recipient Email', title: 'Recipient Email', value: '#recipient:email#' },
	{ label: 'Recipient Mobile', title: 'Recipient Mobile', value: '#recipient:mobile#' },
	{ label: 'This email was sent to', title: 'This email was sent to', value: '#emailSentTo#' },
	{
		label: 'View in Browser',
		title: 'View in Browser',
		value:
			'<a href="#trackingDomain#/show_campaign/#campaign:key#/#recipient:key#/#ab#"><span class="custom-style-link">View in Browser</span></a>',
	},
];

export const ElementLimits = {
	[AllComponentTypes.spacer]: {
		height: { min: 5, max: 2000 },
	},
	[AllComponentTypes.submit_button]: {},
	[AllComponentTypes.image]: {},
	[AllComponentTypes.text]: {},
	[AllComponentTypes.article]: {},
	//[AllComponentTypes.described_image]: {},
	[AllComponentTypes.html]: {},
	[AllComponentTypes.social_follow]: {},
	[AllComponentTypes.timer]: {},
	[AllComponentTypes.video]: {},
	[AllComponentTypes.form]: {},
	[AllComponentTypes.product]: {},
	[AllComponentTypes.coupon]: {},
	[AllComponentTypes.radio]: {},
	[AllComponentTypes.checkbox]: {},
	[AllComponentTypes.checkbox_group]: {},
	[AllComponentTypes.gdpr]: {},
	[AllComponentTypes.recaptcha]: {},
	[AllComponentTypes.email_input]: {},
	[AllComponentTypes.phone_input]: {},
	[AllComponentTypes.text_input]: {},
	[AllComponentTypes.longtext_input]: {},
	[AllComponentTypes.singleselect_input]: {},
	[AllComponentTypes.multiselect_input]: {},
	[AllComponentTypes.date_input]: {},
	[AllComponentTypes.number_input]: {},
	[AllComponentTypes.file_upload]: {},
};

export const ApiAuthorisationModes = {
	Private: 'Private',
	Public: 'Public',
	Organization: 'Organization',
};

export const PublicOptions = [
	{
		value: ApiAuthorisationModes.Private,
		label: ApiAuthorisationModes.Private,
	},
	{
		value: ApiAuthorisationModes.Public,
		label: ApiAuthorisationModes.Public,
	},
	{
		value: ApiAuthorisationModes.Organization,
		label: ApiAuthorisationModes.Organization,
	},
];

export const TemplateAccessLevel = [
	{
		value: ApiAuthorisationModes.Private,
		label: 'Current environment',
	},
	{
		value: ApiAuthorisationModes.Organization,
		label: 'Organization',
	},
];

export const SlotsNotAllowedForElement = {
	[AllComponentTypes.radio]: [],
	[AllComponentTypes.checkbox]: [],
	[AllComponentTypes.checkbox_group]: [],
	[AllComponentTypes.gdpr]: [],
	[AllComponentTypes.recaptcha]: [SlotTypes.ONE_FOURTH, SlotTypes.ONE_THIRD],
	[AllComponentTypes.email_input]: [],
	[AllComponentTypes.phone_input]: [],
	[AllComponentTypes.text_input]: [],
	[AllComponentTypes.longtext_input]: [],
	[AllComponentTypes.singleselect_input]: [],
	[AllComponentTypes.multiselect_input]: [],
	[AllComponentTypes.date_input]: [],
	[AllComponentTypes.number_input]: [],
	[AllComponentTypes.file_upload]: [],
	[AllComponentTypes.spacer]: [],
	[AllComponentTypes.submit_button]: [],
	[AllComponentTypes.image]: [],
	[AllComponentTypes.text]: [],
	[AllComponentTypes.html]: [],
	[AllComponentTypes.social_follow]: [],
};

export const SlotsNotAllowedForElementDesigner = {
	[AllComponentTypes.radio]: [],
	[AllComponentTypes.checkbox]: [],
	[AllComponentTypes.checkbox_group]: [],
	[AllComponentTypes.gdpr]: [],
	[AllComponentTypes.recaptcha]: [SlotTypes.ONE_FOURTH, SlotTypes.ONE_THIRD],
	[AllComponentTypes.email_input]: [],
	[AllComponentTypes.phone_input]: [],
	[AllComponentTypes.text_input]: [],
	[AllComponentTypes.longtext_input]: [],
	[AllComponentTypes.singleselect_input]: [],
	[AllComponentTypes.multiselect_input]: [],
	[AllComponentTypes.date_input]: [],
	[AllComponentTypes.number_input]: [],
	[AllComponentTypes.file_upload]: [],
	[AllComponentTypes.spacer]: [],
	[AllComponentTypes.submit_button]: [],
	[AllComponentTypes.image]: [],
	[AllComponentTypes.text]: [],
	[AllComponentTypes.html]: [],
	[AllComponentTypes.social_follow]: [],
};

export const googleFontBaseUrl = 'https://fonts.googleapis.com/css?family=';

export const googleFontEmbed = (fonts) => `<link href="${googleFontBaseUrl}${fonts}&amp;subset=greek,greek-ext" rel="stylesheet">`;
export const playMark =
	'https://moosendimages.imgix.net/20190426-2019-0426-2019-042620190426/f77e770c356f49a6a4b2e954e195a003video_play.png?fit=clip&ixjsv=2.2.4&w=50';
export const noThumbnail = 'https://i.ibb.co/vhXqnDd/no-thumbnail.jpg';

export const AcceptedFilterGroups = [
	'Facebook, LinkedIn, Twitter, Button, Toggle',
	'Facebook, LinkedIn, Twitter, Instagram, Pinterest, YouTube, Tumblr, Button, Toggle',
	'Reset Image Size Crop Resize',
];

export const ckeditorToolbar = [
	{
		name: 'clipboard',
		groups: ['new', 'clipboard'],
		items: ['NewPage', '-', 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord'],
	},
	{
		name: 'links',
		items: ['Link', 'Unlink', 'Anchor'],
	},
	{
		name: 'others',
		items: ['-'],
	},
	{
		name: 'paragraph',
		groups: ['list', 'indent', 'blocks', 'align', 'bidi'],
		items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
	},
	{
		name: 'justify',
		groups: ['justify'],
		items: ['JustifyRight', 'JustifyLeft', 'JustifyCenter', 'JustifyBlock'],
	},
	{
		name: 'basicstyles',
		groups: ['basicstyles', 'cleanup'],
		items: ['Bold', 'Italic', 'Underline', 'Strike', '-', 'RemoveFormat'],
	},
	'/', //second line
	{
		name: 'styles',
		items: ['Styles', 'Format', 'Font', 'FontSize'],
	},
	{
		name: 'colors',
		items: ['TextColor', 'BGColor'],
	},
	{
		name: 'personalizationtags',
		items: ['PersonalizationTags'],
	},
	{
		name: 'document',
		items: ['ShowBlocks'],
	},
];

export const PublicCustomRowImg = {
	FullWidthRSSArticle: FullWidthRSSArticleIcon,
	FullWidthCartAbandonment: FullWidthCartAbandonmentIcon,
	FullWidthProductRecommendation: FullWidthProductRecommendationIcon,
	HalfWidthProductRecommendation: HalfWidthProductRecommendationIcon,
	ThirdRSSArticle: ThirdRSSArticleIcon,
	ThirdCartAbandonment: ThirdCartAbandonmentIcon,
};

// export const PublicCustomRowHelperImg = {
// 	FullWidthRSSArticle: FullWidthRSSArticleIconGrid,
// 	FullWidthCartAbandonment: FullWidthCartAbandonmentIconGrid,
// 	FullWidthProductRecommendation: FullWidthProductRecommendationIconGrid,
// 	HalfWidthProductRecommendation: HalfWidthProductRecommendationIconGrid,
// 	ThirdRSSArticle: ThirdRSSArticleIconGrid,
// 	ThirdCartAbandonment: ThirdCartAbandonmentIconGrid
// };

export const PublicCustomRowHelperImg = {
	FullWidthRSSArticle: FullWidthRSSArticleIcon,
	FullWidthCartAbandonment: FullWidthCartAbandonmentIcon,
	FullWidthProductRecommendation: FullWidthProductRecommendationIcon,
	HalfWidthProductRecommendation: HalfWidthProductRecommendationIcon,
	ThirdRSSArticle: ThirdRSSArticleIcon,
	ThirdCartAbandonment: ThirdCartAbandonmentIcon,
};

export const BrowserColors = [
	'aliceblue',
	'antiquewhite',
	'aqua',
	'aquamarine',
	'azure',
	'beige',
	'bisque',
	'black',
	'blanchedalmond',
	'blue',
	'blueviolet',
	'brown',
	'burlywood',
	'cadetblue',
	'chartreuse',
	'chocolate',
	'coral',
	'cornflowerblue',
	'cornsilk',
	'crimson',
	'cyan',
	'darkblue',
	'darkcyan',
	'darkgoldenrod',
	'darkgray',
	'darkgreen',
	'darkkhaki',
	'darkmagenta',
	'darkolivegreen',
	'darkorange',
	'darkorchid',
	'darkred',
	'darksalmon',
	'darkseagreen',
	'darkslateblue',
	'darkslategray',
	'darkturquoise',
	'darkviolet',
	'deeppink',
	'deepskyblue',
	'dimgray',
	'dodgerblue',
	'firebrick',
	'floralwhite',
	'forestgreen',
	'fuchsia',
	'gainsboro',
	'ghostwhite',
	'gold',
	'goldenrod',
	'gray',
	'green',
	'greenyellow',
	'honeydew',
	'hotpink',
	'indianred',
	'indigo',
	'ivory',
	'khaki',
	'lavender',
	'lavenderblush',
	'lawngreen',
	'lemonchiffon',
	'lightblue',
	'lightcoral',
	'lightcyan',
	'lightgoldenrodyellow',
	'lightgray',
	'lightgreen',
	'lightpink',
	'lightsalmon',
	'lightseagreen',
	'lightskyblue',
	'lightslategray',
	'lightsteelblue',
	'lightyellow',
	'lime',
	'limegreen',
	'linen',
	'magenta',
	'maroon',
	'mediumaquamarine',
	'mediumblue',
	'mediumorchid',
	'mediumpurple',
	'mediumseagreen',
	'mediumslateblue',
	'mediumspringgreen',
	'mediumturquoise',
	'mediumvioletred',
	'midnightblue',
	'mintcream',
	'mistyrose',
	'moccasin',
	'navajowhite',
	'navy',
	'oldlace',
	'olive',
	'olivedrab',
	'orange',
	'orangered',
	'orchid',
	'palegoldenrod',
	'palegreen',
	'paleturquoise',
	'palevioletred',
	'papayawhip',
	'peachpuff',
	'peru',
	'pink',
	'plum',
	'powderblue',
	'purple',
	'rebeccapurple',
	'red',
	'rosybrown',
	'royalblue',
	'saddlebrown',
	'salmon',
	'sandybrown',
	'seagreen',
	'seashell',
	'sienna',
	'silver',
	'skyblue',
	'slateblue',
	'slategray',
	'snow',
	'springgreen',
	'steelblue',
	'tan',
	'teal',
	'thistle',
	'tomato',
	'turquoise',
	'violet',
	'wheat',
	'white',
	'whitesmoke',
	'yellow',
	'yellowgreen',
];

export const MoosendPalette = {
	id: '455d4e0c-3acc-467a-a88a-62725bf6162c',
	palette: {
		...variables,
		backgroundColors: {
			...variables.backgroundColors,
			primary1: '#3fbaca',
			dropdown: '#FFFFFF',
			primary2: '#293346',
			primary4: '#d84840',
			background: '#f7f7f7',
			altWhite: '#293346',
			submenu: '#425579',
			primary3: '#F0B941',
			teal500: '#3fbaca',
			medgray1: '#9d9d9d',
			secondary1: '#74c374',
			autosExpression: '#425579',
			client: '#444444',
			accountNavigation: '#293346',
			createNew: '#3fbaca',
			blue400: '#3fbaca',
			teal400: '#F0B941',
			orange400: '#ECF0FC',
			red400: '#d84840',
		},
		fontColors: {
			...variables.fontColors,
			primary1: '#3fbaca',
			text: '#454545',
			inverseText: '#FFFFFF',
			dropdown: '#454545',
			primary3: '#F0B941',
			primary2: '#293346',
			teal500: '#3fbaca',
			secondary1: '#74c374',
			primary4: '#d84840',
			createNew: '#FFFFFF',
			accountNavigation: '#FFFFFF',
		},
		borderColors: {
			...variables.borderColors,
			gray1: '#e2e2e2',
			primary1: '#3fbaca',
			teal500: '#3fbaca',
			darkGray2: '#444444',
			primary4: '#d84840',
			client: '#444444',
		},
		styles: {
			buttonBorderRadius: '2px',
		},
		reportColors: {
			box1: '#3fbaca',
			box2: '#d84840',
			box3: '#293346',
			box4: '#F0B941',
			box5: '#293346',
			box6: '#3fbaca',
			box7: '#F0B941',
			box8: '#d84840',
			text: '1A1A1A',
			unsubChart: '#FC8181',
			bounceChart: '#eccb4c',
			lowChart: '#d3d3d3',
			circleChart1: '#3fbaca',
			circleChart2: '#d84840',
			circleChart3: '#E0E0E0',
			restChart1: '#3fbaca',
			restChart2: '#d84840',
			restChart3: '#E0E0E0',
			restChart4: '#F0B941',
			restChart5: '#293346',
			restChart6: '#d3d3d3',
			restChart7: '#f4ad55',
			restChart8: '#f9b3ce',
			restChart9: '#40b4ac',
			restChart10: '#4262e1',
			restChart11: '#d5b9f9',
			restChart12: '#eb8838',
		},
	},
	colors: {
		first: '#3fbaca',
		second: variables.backgroundColors.white,
		third: '#293346',
	},
};

export const SitecoreSendPalette = {
	id: '5ffbd582-0adb-4c41-a0a3-fe83a7cc253e',
	palette: {
		...variables,
		reportColors: {
			box1: '#4299e1',
			box2: '#f56565',
			box3: '#eec94b',
			box4: '#1A1A1A',
			box5: '#1A1A1A',
			box6: '#4299e1',
			box7: '#38B2AC',
			box8: '#f56565',
			text: '1A1A1A',
			unsubChart: '#FC8181',
			bounceChart: '#eccb4c',
			lowChart: '#d3d3d3',
			circleChart1: '#5a80f4',
			circleChart2: '#b3c6ff',
			circleChart3: '#d3deff',
			restChart1: '#4596e0',
			restChart2: '#55d3c5',
			restChart3: '#eccb4c',
			restChart4: '#9e72e9',
			restChart5: '#eb5aa6',
			restChart6: '#d3d3d3',
			restChart7: '#f4ad55',
			restChart8: '#f9b3ce',
			restChart9: '#40b4ac',
			restChart10: '#4262e1',
			restChart11: '#d5b9f9',
			restChart12: '#eb8838',
		},
	},
	colors: {
		first: variables.backgroundColors.primary1,
		second: variables.backgroundColors.white,
		third: variables.backgroundColors.primary2,
	},
};

export const BrandingPalettes = [MoosendPalette, SitecoreSendPalette];

export const MobilePropsToBeIgnored = [
	'slots',
	'components',
	'isMobileView',
	'uniqueId',
	'customFonts',
	'disableAutoComponents',
	'failAction',
	'successAction',
	'lastPage',
	'rows',
	'retina_images',
	'watermark',
	'title',
	'script',
	'templateId',
	'limitedComponents',
	'newsletterWidth',
	'primaryProduct',
	'usedColors',
	'mobileProps',
];

export const BaseSize = 1024;
export const UnitsConfig = {
	Bytes: { base: 1, enabled: false },
	KB: { base: BaseSize, enabled: true },
	MB: { base: BaseSize * BaseSize, enabled: true },
};
