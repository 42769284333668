import { create } from 'zustand';
import { produce } from 'immer';
import { isNull } from 'lodash';
import { getSiteCollections, getSites } from '@/util/apiUtils/xmcApps';
import { NotificationTypes } from '../util/resources';
import { showNotification } from './NotificationStore';

export const initialState = {
	sites: {
		items: [],
		collections: [],
		types: [],
		dataset: [],
	},
	loaders: {
		sites: {
			table: false,
			tableDataUpdate: false,
		},
	},
};

const useXmcAppsStore = create((set, get) => ({
	...initialState,
	getSites: async (sites, callback) => {
		set(
			produce((state) => {
				state.loaders.sites.table = true;
			}),
		);

		try {
			const collectionsRsp = await getSiteCollections();
			const sitesRsp = await getSites();
			const entitySites = [...sites];
			let shouldUpdateEntitySites;

			const sitesData = [];
			let siteIndex = 0;

			sitesRsp.data.forEach((site) => {
				const storedSiteIndex = entitySites.findIndex((item) => item.id === site.id);

				site.hosts.forEach((host, hostIndex) => {
					sitesData.push({
						id: host.id,
						name: host.name,
						collection: collectionsRsp.data.find((item) => item.id === site.collectionId).name,
						path: host.settings.rootPath,
						type: host.settings.IsSxaSite === 'true' ? 'SXA' : 'non-SXA',
						isSelected: !sites.length
							? false
							: Boolean(sites.find((s) => s.id === host.id) || (sites.find((s) => s.id === site.id) && hostIndex === 0)),
						idx: siteIndex,
					});

					if (!entitySites.find((item) => item.id === host.id) && entitySites.find((item) => item.id === site.id) && hostIndex === 0) {
						entitySites.splice(storedSiteIndex, 1, {
							id: host.id,
							name: host.name,
						});
						shouldUpdateEntitySites = true;
					}

					siteIndex++;
				});
			});

			const collectionsData = collectionsRsp.data.map((item) => item.name);

			const types = ['SXA', 'non-SXA'];

			set(
				produce((state) => {
					state.sites.items = sitesData;
					state.sites.collections = collectionsData;
					state.sites.types = types;
					state.sites.dataset = sitesData;
					state.loaders.sites.table = false;
				}),
			);

			callback && callback(entitySites, sitesData, shouldUpdateEntitySites);
		} catch (e) {
			showNotification({
				type: NotificationTypes.ERROR,
				text: 'Failed to retrieve sites. Please try refreshing the page.',
			});

			set(
				produce((state) => {
					state.loaders.sites.table = false;
				}),
			);
		}
	},
	searchSites: (filters = { selection: null }, order) => {
		set(
			produce((state) => {
				state.loaders.sites.tableDataUpdate = true;
			}),
		);

		let items = [...get().sites.dataset];

		if (filters.collection || filters.type || filters.searchTerm || !isNull(filters.selection)) {
			items = items.filter((site) => {
				if (filters.collection && site.collection !== filters.collection) {
					return false;
				}

				if (filters.type && site.type !== filters.type) {
					return false;
				}

				if (filters.searchTerm && !site.name.toLowerCase().includes(filters.searchTerm.toLowerCase())) {
					return false;
				}

				if (!isNull(filters.selection) && site.isSelected !== filters.selection) {
					return false;
				}

				return true;
			});
		}

		if (order) {
			const regex = /^([A-Za-z]+)(?:A|De)scending$/;
			const sortBy = order.match(regex)[1].toLowerCase();
			const isAscending = order.endsWith('Ascending');
			const sign = isAscending ? 1 : -1;

			items.sort((siteA, siteB) => {
				if (siteA[sortBy] < siteB[sortBy]) {
					return sign * -1;
				}

				if (siteA[sortBy] > siteB[sortBy]) {
					return sign * 1;
				}

				return 0;
			});
		}

		set(
			produce((state) => {
				state.sites.items = items;
				state.loaders.sites.tableDataUpdate = false;
			}),
		);
	},
	selectSiteAndSearch: (idx, isSelected, filters, order) => {
		set(
			produce((state) => {
				state.loaders.sites.tableDataUpdate = true;
			}),
		);
		const dataset = [...get().sites.dataset];

		dataset[idx] = { ...dataset[idx], isSelected };

		set(
			produce((state) => {
				state.sites.dataset = dataset;
			}),
		);

		get().searchSites(filters, order);
	},
	selectAllSitesAndSearch: (isSelected, filters, order) => {
		set(
			produce((state) => {
				state.loaders.sites.tableDataUpdate = true;
			}),
		);
		const items = get().sites.items;

		set(
			produce((state) => {
				items.forEach((item) => {
					state.sites.dataset[item.idx] = { ...item, isSelected };
				});
			}),
		);

		get().searchSites(filters, order);
	},
	resetSites: (sites) => {
		const dataset = get().sites.dataset.map((site) => ({
			...site,
			isSelected: !sites.length ? false : Boolean(sites.find((s) => s.id === site.id)),
		}));

		set(
			produce((state) => {
				state.sites.dataset = dataset;
				state.sites.items = dataset;
			}),
		);
	},
}));

export default useXmcAppsStore;
