import React from 'react';

import { Box } from '@chakra-ui/react';
import config from '../../../../config/config';
import { claimsKeys, getAuthClient, getUser } from '../../../util/auth/auth';

export const AppSwitcher = () => {
	React.useEffect(() => {
		const auth = getAuthClient();
		const user = getUser();
		try {
			const appSwitcher = document.querySelector('app-switcher');

			if (!appSwitcher) return;

			appSwitcher?.componentOnReady().then(() => {
				appSwitcher?.init({
					getAccessToken: (params) => auth.getTokenSilently({ authorizationParams: params }),
					loginWithRedirect: (params) =>
						auth.loginWithRedirect({
							appState: { returnTo: window.location.pathname + location.search },
							authorizationParams: { ...params, redirect_uri: params.redirectUri, redirectUri: undefined, tenant_name: undefined },
						}),
					environment: config.authentication.appSwitcherEnv,
					organizationId: user[claimsKeys.ORG_ID],
					tenantId: user[claimsKeys.TENANT_ID],
				});
			});
		} catch (error) {
			console.log(error);
			if (error.error !== 'login_required') {
				throw error;
			}
			auth.redirectToLogin();
		}
	}, []);

	return (
		<Box display="flex" alignItems="center">
			<app-switcher></app-switcher>
		</Box>
	);
};
