import axios from 'axios';
import { emptyDefaultJson, NotificationTypes } from '../resources';
import config, { importedConfig } from '../../../config/config';
import { WarningMessages, ErrorMessages } from '../../../config/messages';
import * as TemplateUtils from './template';
import * as EntityUtils from './entities.js';
import * as CustomRowUtils from './customRows';
import * as CustomElementUtils from './customElements';

import { getEditorType, getEntityId, getPaletteById } from '../../util/helper';

import useTemplateStore from '../../stores/TemplateStore';
import useContentStore from '../../stores/ContentStore';
import { showNotification } from '../../stores/NotificationStore';

import useCommentStore from '../../stores/CommentStore';
import useLeftSidebarStore from '../../stores/LeftSidebarStore';
import useEntitiesStore from '../../stores/EntitiesStore';
import useUserStore from '@/stores/UserStore';
import { EntityStatuses } from '@/components/Table/strings';
import WebStorage from '../webStorage';

// import Moment from 'moment';
// import {generateGuid} from '../helper';
// import temp from './temp.json';

export const loadInitialData = async () => {
	let templatesMetadata = [],
		allDraftsMetadata = [];
	const editorType = getEditorType();
	const metadataCategory = TemplateUtils.getDraftCategory();

	const contentStore = useContentStore.getState();

	const palette = getPaletteById(localStorage.getItem('moo_theme'));
	if (palette) {
		contentStore.setPalette(palette);
	}

	useCommentStore.getState().getComments();

	useEntitiesStore.getState().getWebhooks();
	const [templatesRsp, draftsRsp, autosaveDraftsRsp, userDetailsRsp, customRowsRsp, customElementsRsp] = await axios.all([
		TemplateUtils.getTemplates(),
		TemplateUtils.getDrafts(),
		TemplateUtils.getAutosaveDrafts(),
		TemplateUtils.getUserDetails(),
		CustomRowUtils.getCustomRows(),
		CustomElementUtils.getCustomElements(),
	]);

	if (userDetailsRsp && userDetailsRsp.data) {
		useTemplateStore.getState().setFavouriteTemplates(TemplateUtils.getFavouriteTemplatesFromRsp(userDetailsRsp));
	}

	if (customRowsRsp && customRowsRsp.data.Data) {
		useLeftSidebarStore.getState().loadCustomRows(CustomRowUtils.getCustomRowsFromRsp(customRowsRsp));
	}

	if (customElementsRsp && customElementsRsp.data.Data) {
		useLeftSidebarStore.getState().loadCustomElements(CustomElementUtils.getCustomElementsFromRsp(customElementsRsp));
	}
	//extract merge drafts and autosaved drafts
	allDraftsMetadata = TemplateUtils.extractAndMergeAllDraftsFromResponses([
		draftsRsp,
		{ data: { Data: autosaveDraftsRsp.data ? [autosaveDraftsRsp.data] : [] } },
	]);

	useLeftSidebarStore.getState().loadDraftsMetadata(allDraftsMetadata);

	templatesMetadata = TemplateUtils.getTemplatesFromRsp(templatesRsp);

	useTemplateStore.getState().loadTemplatesMetadata(templatesMetadata);

	useTemplateStore.getState().loadTemplateCategories(TemplateUtils.getTemplateCategoriesFromTemplatesMetadata(templatesMetadata));

	if (!allDraftsMetadata.length) {
		contentStore.setContentJson(emptyDefaultJson[editorType]);
	}

	//returns a promise result of the call needed to be made based on some conditions
	let contentRsp = await resolveContentToLoad(
		allDraftsMetadata,
		TemplateUtils.getDraftsFromRsp(draftsRsp),
		TemplateUtils.getDraftsFromRsp({ data: { Data: autosaveDraftsRsp.data ? [autosaveDraftsRsp.data] : [] } }),
		templatesMetadata,
		importedConfig.query.showTemplate,
	);

	switch (contentRsp && contentRsp.data.Category) {
		case metadataCategory.EDITOR_SAVED_DRAFTS:
		case metadataCategory.EDITOR_SAVED_GENERIC_ENTRY:
		case metadataCategory.EDITOR_SAVED_DRAFTS_AUTOSAVE:
			let draft = TemplateUtils.getDraftFromRsp(contentRsp);
			contentStore.setDraftAsContentJson(draft);
			break;
		case 'EDITOR_SAVED_TEMPLATES':
		case 'EDITONLY_SAVED_TEMPLATES':
		case 'EDITONLY_DESIGNER_SAVED_TEMPLATES':
			let template = TemplateUtils.getTemplateFromRsp(contentRsp);

			contentStore.setDraftAsContentJson(template);
			break;
	}

	return allDraftsMetadata;
};

export const loadInitialMoosendData = async (allDraftsMetadata = []) => {
	const editorType = getEditorType();

	const contentStore = useContentStore.getState();

	try {
		const [designerRsp] = await axios.all([EntityUtils.getEntity(getEntityId())]);

		if (designerRsp && designerRsp.data) {
			let canBeModified = true;

			if (designerRsp.data.Entity.Status === EntityStatuses.active) {
				canBeModified = WebStorage.get('canBeModified') ?? false;
			}

			useContentStore.getState().setCanBeModified(canBeModified);
			useContentStore.getState().setFormModified(false);

			useEntitiesStore.getState().changeEntityData({
				activeEntity: designerRsp.data,
				entityForm: {
					name: designerRsp.data.Entity.Name,
					webhookId: designerRsp.data.WebhookSettings ? designerRsp.data.WebhookSettings.WebhookId : null,
					show: true,
				},
			});

			if (!allDraftsMetadata.length && designerRsp.data.Blueprints) {
				const jsonBlueprintString = designerRsp.data.Blueprints.JsonContent;

				const jsonBlueprint = jsonBlueprintString && JSON.parse(jsonBlueprintString);

				jsonBlueprint && contentStore.setBlueprints(jsonBlueprint);
			}
		}

		contentStore.setData({ dataLoading: false });
	} catch (error) {
		if (error instanceof SyntaxError) {
			//check if JSON.parse failed

			contentStore.setContentJson(emptyDefaultJson[editorType]);

			// setTimeout(() => { //a small set timeout here to wait for ui load
			showNotification({
				type: NotificationTypes.WARNING,
				ms: 7000,
				text: WarningMessages.OLD_JSON_CORRUPTED,
			});
			// },1000);
		} else {
			showNotification({
				type: NotificationTypes.ERROR,
				ms: 7000,
				text: ErrorMessages.MOOSEND_DATA_NOT_LOADED,
			});
		}

		contentStore.setData({ dataLoading: false });

		console.error(error, true);
		throw new Error('Halt');
	}
};

//returns a promise result of the call needed to be made based on some conditions, could be a draft, an autosave draft or a template if showTemplate query string is present
export const resolveContentToLoad = (allDraftsMetadata, draftsMetadata, autosaveDraftsMetadata, templatesMetadata, showTemplate) => {
	if (allDraftsMetadata.length > 0 && !showTemplate) {
		let draftToLoad = allDraftsMetadata[0]; //we want to load the first one, they're ordered by date saved
		let getDraftFunc;

		//check if draft is in drafts or autosaveDrafts
		let draftIsDraft = draftsMetadata.find((draft) => draftToLoad.id === draft.id);
		let draftIsAutosaveDraft = autosaveDraftsMetadata.find((draft) => draftToLoad.id === draft.id);

		if (draftIsDraft) {
			getDraftFunc = TemplateUtils.getDraft;
		} else if (draftIsAutosaveDraft) {
			getDraftFunc = TemplateUtils.getAutosaveDraft;
		} else {
			throw new Error(ErrorMessages.DRAFT_NOT_EXIST);
		}

		return getDraftFunc(draftToLoad);
	} else if (showTemplate) {
		let showTemplateMetadata = templatesMetadata.find((md) => md.id === showTemplate);
		if (showTemplateMetadata) {
			return TemplateUtils.getTemplate(showTemplateMetadata);
		} else {
			showNotification({ type: NotificationTypes.WARNING, ms: 7000, text: WarningMessages.TEMPLATE_NOT_FOUND });
		}
	}
};
