import React from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { ErrorMessages } from '../config/messages';

import { GridOptions, NotificationTypes } from './util/resources';
import App from './components/App.react';
import TableRoot from './components/Table/containers/TableRoot';
import { NoAccessLP } from './components/NoAccessLP';
import Preview from './components/gui/layout/Preview.react';
import TemplateMarket from './components/gui/layout/TemplateMarket.react';
import { Conditions } from './components/Conditions/Conditions';
import MainBody from './components/gui/layout/MainBody.react';
import { contentViewMenuItemConstants as ViewTypes } from './util/resources';
import { getEntityId, pathPrefix } from './util/helper';

import transform from './components/transformer/transformer';

import { showNotification } from './stores/NotificationStore';

import useContentStore from './stores/ContentStore';
import useEntitiesStore, { initialState } from './stores/EntitiesStore';
import { changeView } from './stores/ContentViewStore';
import { runEditor } from './module';
import { EntitiesRoutes, getRoute } from './components/Table/strings';
import { clearAutosaves } from './util/autosave';
import { SettingsPage } from './components/gui/layout/SettingsPage.react';
import { canAccessEntities } from './util/apiUtils/entities';
import useStylingStore from './stores/StylingStore';

import { theme } from './util/theme';
import { toastOptions } from '@sitecore/blok-theme';
import { ChakraProvider, Link } from '@chakra-ui/react';
import WebStorage from './util/webStorage';
import useUserStore from './stores/UserStore';

/**
 * all routes and route changes MUST include the path_prefix from the config in order to account for the editor's deployment on a path for staging and prod
 */

const Routes = () => {
	const [canAccess, setCanAccess] = React.useState(null);

	React.useEffect(() => {
		useUserStore.getState().getTenants();

		// Check if the visitor has access and if not, stop everything here and redirect to "no access" page
		canAccessEntities()
			.then(() => {
				setCanAccess(true);
			})
			.catch(() => {
				setCanAccess(false);
				browserHistory.push('/no-access');
			});
	}, []);

	if (canAccess === null) return null;

	return (
		<ChakraProvider theme={theme} toastOptions={toastOptions}>
			<Router history={browserHistory}>
				<Route path={'/'} component={() => browserHistory.push(getRoute.root())} />
				<Route path={EntitiesRoutes.root} component={TableRoot} onEnter={onNonEditorRouterEnter} />
				<Route path={EntitiesRoutes.stylingEdit} component={TableRoot} onEnter={onNonEditorRouterEnter} />
				<Route path={EntitiesRoutes.stylingList} component={TableRoot} onEnter={onNonEditorRouterEnter} />
				<Route path={'/no-access'} component={NoAccessLP} onEnter={onNonEditorRouterEnter} />
				<Route onLeave={onEditorLeave} onEnter={onEditorEnter} path={pathPrefix()} component={App}>
					<IndexRoute onEnter={onMainBodyEnter} component={MainBody} />
					<Route path={`${pathPrefix()}/send-test`} component={MainBody} />
					<Route path={`${pathPrefix()}/script-menu`} component={MainBody} />
					{/* <Route onEnter={onPreviewEnter} path={`${pathPrefix()}/code-options`} component={CodeOptionsMenu} /> */}
					<Route path={`${pathPrefix()}/save-template`} component={MainBody} />
					<Route path={`${pathPrefix()}/drafts`} component={MainBody} />
					<Route path={`${pathPrefix()}/save-custom-row`} component={MainBody} />
					<Route path={`${pathPrefix()}/save-custom-element`} component={MainBody} />
					<Route onEnter={onCommentsEnter} path={`${pathPrefix()}/comment-mode`} component={MainBody} />
					<Route onEnter={onPreviewEnter} path={`${pathPrefix()}/preview`} component={Preview} />
					<Route path={`${pathPrefix()}/template-library`} component={TemplateMarket} />
					<Route path={`${pathPrefix()}/conditions`} component={Conditions} />
					<Route onEnter={onPreviewEnter} path={`${pathPrefix()}/settings`} component={SettingsPage} />
				</Route>

				<Route path={`${pathPrefix()}/favicon.ico`} />
			</Router>
		</ChakraProvider>
	);
};

function onEditorEnter() {
	useContentStore.getState().clearContent();
	useStylingStore.getState().getAllStyles();
	runEditor();
}

function onEditorLeave() {
	clearAutosaves();
	useEntitiesStore.getState().changeEntityData({ activeEntity: undefined, entityForm: initialState.entityForm });
	useContentStore.getState().setData({ dataLoading: true });
	WebStorage.remove('canBeModified');
}

function onMainBodyEnter(nextState, replaceState) {
	changeView(ViewTypes.DESIGN_VIEW);
}

function onPreviewEnter(nextState, replaceState) {
	const contentStore = useContentStore.getState();

	const { logicsErrors, isValid } = contentStore.checkLogicsValidity();

	if (contentStore.dataLoading) {
		setTimeout(() => {
			onPreviewEnter();
		}, 300);
		return;
	}

	let errorCb = () => showNotification({ text: ErrorMessages.PREVIEW_ERROR, type: NotificationTypes.ERROR });

	contentStore.setPreviewHtml(transform(contentStore.content, true, logicsErrors), null, errorCb);

	if (!isValid) {
		showNotification({
			type: NotificationTypes.ERROR,
			text: (
				<>
					Fix invalid <Link onClick={() => browserHistory.push(`/design/conditions?entityId=${getEntityId()}`)}>logic</Link> conditions to
					preview the form with logic
				</>
			),
		});
	}
}

function onCommentsEnter() {
	useContentStore.getState().saveContentHelper({ grid: GridOptions.DISABLED });
}

function onNonEditorRouterEnter() {
	WebStorage.remove('canBeModified');
}

export default Routes;
