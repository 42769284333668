import React from 'react';
import { Box, Image, Text, Button, IconButton, Spacer, Tooltip } from '@chakra-ui/react';
import { Icon } from '@/components/gui/shared/Icon';
import { mdiHomeVariantOutline } from '@mdi/js';
import { getAuthClient, claimsKeys } from '../../../util/auth/auth';
import config from '../../../../config/config';
import { AppSwitcher } from './AppSwitcher';
import { AccountMenu } from './AccountMenu';
import { withRouter } from 'react-router';
import { getRoute } from '../strings';
import { Link } from 'react-router';

export const TableTopBar = ({ noAccess }) => {
	const gotoTools = () => {
		const redir = async () => {
			const auth = getAuthClient();
			const user = await auth.getUser();
			const tenantName = user[claimsKeys.TENANT_NAME];
			const orgId = user[claimsKeys.ORG_ID];

			location.href = `${config.xmc.dashboard_url}?organization=${orgId}&tenantName=${tenantName}`;
			return;
		};

		redir();
	};

	return (
		<Box as="header" layerStyle="section.topbar" flex="0 0 auto" display="flex" justifyContent="space-between" zIndex={2}>
			<Box display="flex" alignItems="center" gap="2">
				<AppSwitcher />
				<Tooltip label="XM Cloud Home">
					<IconButton
						aria-label="XM Cloud Home"
						variant="ghost"
						icon={<Icon path={mdiHomeVariantOutline} />}
						size="sm"
						data-testid="home-icon"
						onClick={gotoTools}
					/>
				</Tooltip>
				<Link to={getRoute.root('forms', 'list', 1)}>
					<Image h="8" w="auto" p="1" src="https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/logo-forms" />
				</Link>
				<Spacer />
				{!noAccess && (
					<>
						<TopBarButton label="Forms" path={getRoute.root('forms', 'list', 1)} isActive={location.pathname.startsWith('/forms/list')} />
						<TopBarButton
							label="Webhooks"
							path={getRoute.root('forms', 'webhook', 1)}
							isActive={location.pathname.startsWith('/forms/webhook')}
						/>
						<TopBarButton label="Styles" path={getRoute.stylingList()} isActive={location.pathname.startsWith('/forms/styling')} />
					</>
				)}
			</Box>
			<Box>
				<AccountMenu />
			</Box>
		</Box>
	);
};

const TopBarButton = withRouter((props) => {
	const { label, path, styles, isActive, router } = props;

	return (
		<Link to={path}>
			<Button variant="navigation" size="sm" isActive={isActive ? true : false} {...styles}>
				{label}
			</Button>
		</Link>
	);
});
